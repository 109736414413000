import React from 'react'
import {useMutation,useQueryClient} from 'react-query'
import { deleteData } from '../../../globals'
import { Button } from '@chakra-ui/react'

export default function DeleteRecord({deleteUrl,queryString}) {
 
    const queryClient = useQueryClient()
 
    //Delete Record
    const deleteMutation = useMutation(queryString,()=>deleteData(deleteUrl),{
    onSuccess: (data)=>{
      console.log("delete Data",data)
      queryClient.invalidateQueries(queryString)
    }
  })

    return (
        <Button onDoubleClick={()=>deleteMutation.mutate()}  colorScheme="red">Delete</Button> 
    )
}   
