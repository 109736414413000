import {GET_SHIPMENTS,FAIL_REQUEST,START_REQUEST, GET_SHIPMENT_BY_ID, ADD_CARRIERS_TO_SHIPMENT, GET_OFFERS, APPROVE_OFFER ,ADD_NEW_SHIPMENT_CUSTOMER ,EDIT_SHIPMENT_CUSTOMER, FILTER_ADMIN_SHIPMENTS} from '../constants'

export const get_shipment_action =(shipments,count)=>{
return {
    type: GET_SHIPMENTS,
    payload:shipments,
    count
}
}
export const start_request=()=>{
    
    return {
        type: START_REQUEST,
    }
}
export const fail_request=()=>{
    return {
        type: FAIL_REQUEST,
    }
}
export const get_shipment_by_id_action =(shipment)=>{
    return {
        type: GET_SHIPMENT_BY_ID,
        payload:shipment,
    }
}
export const get_offers_action =(offers,shipment_id)=>{
    return {
        type: GET_OFFERS,
        payload:offers,
        id:shipment_id
    }
}
export const add_carriers_to_shipment_action =(id,shipment)=>{
    return {
        type: ADD_CARRIERS_TO_SHIPMENT,
        id,
        shipment
    }
}
export const approve_offer_action =(shipment)=>{
    return {
        type: APPROVE_OFFER,
        shipment
    }
}
//----------------------------ADD SHIPMENTS CUSTOMER------------------
export const AddShipmentActionCustomer = (data) => {
  return {
    type: ADD_NEW_SHIPMENT_CUSTOMER,
    data,
  };
};

//----------------------------Edit SHIPMENTS CUSTOMER------------------
export const EditShipmentActionCustomer = (data) => {
  return {
    type: EDIT_SHIPMENT_CUSTOMER,
    data,
  };
};

export const filter_admin_shipments=(filtered_shipments)=>({type:FILTER_ADMIN_SHIPMENTS,filtered_shipments})

 