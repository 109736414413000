import React,{useState,useEffect} from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useToast,Button,Input,FormControl,FormLabel,Field,FormErrorMessage, useAccordionItemState
  } from "@chakra-ui/react"
import { Formik,useFormik } from 'formik';
import MyInput from '../../Forms/MyInput';
import * as yup from 'yup';
import useApiRequest from '../../../Services/useApiRequest';
import MySelect from '../../Forms/MySelect';
import MyDatePicker from '../../Forms/MyDatePicker';
import MySwitch from '../../Forms/MySwitch';
import { useMutation,useQueryClient } from 'react-query'
import {  URL } from '../../../globals';
import axios from 'axios'


function EditRecord({schema,formValues,record,title,getUrl,action,validationSchema,isOpen,onOpen,onClose,queryString}) {
  const queryClient = useQueryClient()
  const toast = useToast()
  const putData = async (body)=>{
    const {data} = await axios.put(`${URL}${getUrl}`,body)
    return data
  }
  // Edit Record Mutation
  const editRecordMutation = useMutation(putData, {
    onSuccess: () => {
      toast({
        title: "Success", 
        description: "Your records were edited succesfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
      queryClient.invalidateQueries(queryString)
      onClose()
    },
  })

  const {sendRequest:editRecord,loading} = useApiRequest({ url:getUrl, type:'put',action:`EDIT_${action?.slice(0,-1)}`});
  const btnRef = React.useRef()
  const formik = useFormik({
    initialValues: {...delKey(schema,record)},
    enableReinitialize:true,
    validationSchema, 
    onSubmit: body =>{
      editRecordMutation.mutate(body)
    }
  });

  return (
    <>
    
      <Drawer
        isOpen={isOpen}
        placement="right"
        size='lg'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Edit {title}</DrawerHeader>
            <DrawerBody>
            <form onSubmit={formik.handleSubmit}>
            {schema.map(s=>{
                  if(s.show.edit){
                      switch (s.type) {
                          case "select":
                             return <MySelect isFetching={true} getUrl={s.getUrl} label1={s.selectLabel1} label2={s.selectLabel2} label={s.label} onChange={formik.setFieldValue} name={s.dbvalue} /> 
                        case "date":
                           return <MyDatePicker selectedDate={formik.values[s.dbvalue]} label={s.label} onChange={formik.setFieldValue} id={s.dbvalue} name={s.dbvalue} error={formik.errors[s.dbvalue]}/>
                        case "boolean":
                          return <MySwitch label='Approved' defaultChecked={formik.initialValues[s.dbvalue]} onChange={formik.handleChange} name={s.dbvalue} />
                          default:
                           return <MyInput label={s.label} value={formik.values[s.dbvalue]}  onChange={formik.handleChange} id={s.dbvalue} name={s.dbvalue} error={formik.errors[s.dbvalue]}/>
                              break;
                      }
                  }
              })}
             
      
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button isLoading={editRecordMutation.isLoading} loadingText="Submitting" colorScheme="blue" type='submit'>Edit</Button>
            </DrawerFooter>
          </form>  
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
    )
}

export default EditRecord

let addRecordSchema = yup.object().shape({
  carrier_name: yup.string().required(),
  carrier_email: yup.string().email().required(),
  carrier_phone: yup.string().required(),
});

function delKey(arr,obj) {
    let myobj={}
    arr.map(item=>{
        if(item.dbvalue == getKeyByValue(obj,item.dbvalue) && item.show.edit==true ) { myobj[item.dbvalue]=obj[item.dbvalue]}
    })
    return myobj
  }

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => key === value);
}