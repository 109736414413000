import { GET_CARRIER_CUSTOMER_CHAT } from "../constants";


const initialstate = {
   admin_customer_chat:[]
   
  };
  
  const chat_reducer = (state = initialstate, action) => {
    if (action.type === GET_CARRIER_CUSTOMER_CHAT) {
      const messages = action.payload;
      const chat_by_shipment = {shipment_id:action.shipment_id,messages}      
      let admin_customer_chat = state.admin_customer_chat;
      admin_customer_chat.push(chat_by_shipment)
      return {
        ...state,
        admin_customer_chat
        }
  };
    return state;
  };
  
  export default chat_reducer;
  