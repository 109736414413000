import { combineReducers } from "redux";
import shipments_reducer from "./shipments_reducer";
import customers_reducer from "./customers_reducer";
import admin_reducer from "./admin_reducer";
import carriers_reducer from "./carriers_reducer";
import chat_reducer from "./chat_reducer";
import customerShip_reducer from "./customerShip_reducer";
import notification_reducer from "./notification_reducer";
import offers_reducer from "./offers_reducer";

const root_reducer = combineReducers({
    shipments_reducer,
    carriers_reducer,
    customers_reducer,
    chat_reducer,
	customerShip_reducer,
    admin_reducer,
    notification_reducer,
    offers_reducer
});

export default root_reducer;
