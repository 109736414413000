import React from 'react'
import ShipperReceiver from '../ShipperReceiver'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import Row from '../../../Common/Layout/Row'
export default function CarrierCustomerData({shipment}) {
    return (

        <Tabs size='sm' isFitted variant="soft-rounded" colorScheme="blue" padding='.5em'>
        <TabList>
            <Tab>Customer</Tab>
            <Tab>Carrier & Truck</Tab>
            <Tab>Shipper Receiver</Tab>
        </TabList>
        <TabPanels>
            <TabPanel>
            {shipment.customer ? shipment.customer.length>0 &&
                    <div className="d-flex flex-column">
                        <p className="offer-description">Customer Information</p>
                        <p  className="gray_text ml-2">{shipment.customer[0].customer_name}</p>
                        <p  className="gray_text ml-2">{shipment.customer[0].customer_email}</p>
                        <p  className="gray_text ml-2">{shipment.customer[0].customer_mobilenum}</p>
             </div>:null}
            </TabPanel>
            <TabPanel>
             <Row>
            {shipment.selected_driver_data ? shipment.selected_driver_data.length>0 &&
                    <div className="d-flex flex-column ">
                        <p className="offer-description">Driver Information</p>
                        <p  className="gray_text ml-2">{shipment.selected_driver_data[0].driver_name}</p>
                        <p  className="gray_text ml-2">{shipment.selected_driver_data[0].driver_email}</p>
                        <p  className="gray_text ml-2">{shipment.selected_driver_data[0].driver_mobilenum}</p>
                    </div>:null}
                    {shipment.selected_truck_data ? shipment.selected_truck_data.length>0 &&
                    <div className="d-flex flex-column ">
                        <p className="offer-description">Truck Information</p>
                        <p  className="gray_text ml-2">{shipment.selected_truck_data[0].vehicle_name}</p>
                        <p  className="gray_text ml-2">{shipment.selected_truck_data[0].registeration_num}</p>
                        <p  className="gray_text ml-2">{shipment.selected_truck_data[0].vehicle_type}</p>
                    </div>:null}
             </Row>  
            </TabPanel>
            <TabPanel>
            <Row>
            {shipment.shipper&& <ShipperReceiver data={shipment.shipper} type='shipper'/>} 
            {shipment.receiver&&<ShipperReceiver data={shipment.receiver} type='receiver'/> } 
            </Row>
            </TabPanel>
        </TabPanels>
        </Tabs>

                   
                    
                   
    )
}
