import React, { Component } from "react";
import "../Styles/Card.css";
import { connect } from 'react-redux'
import { approve_offer_async } from "../Redux/actions/shipment_actions_creators";
import { store } from 'react-notifications-component';
import dateformat from 'dateformat'
import { api_callback } from "../globals";
class OfferCard extends Component {

constructor(props){
   super(props);
   this.state={
      loading:false
   }
}

decline_offer=()=>{

}
accept_offer=(shipment_id,offer_id)=>{
   this.setState({loading:true});
   const body={
      "approval":true,
      "offerid":offer_id
   }
   console.log(body)
   this.props.approve_offer(shipment_id,body,this.accept_offer_callback)
}
accept_offer_callback=(success)=>{
   this.setState({loading:false});
if(success){
   api_callback(true,'Success','Offer Accepted')
   window.location.reload();
}else{
   api_callback(false,'Error','Error Occured!')
}
}


   render() {
     const {
      carrier,
      date_dropoff,
      date_pickup,
      time_pickup,
      time_dropoff,
      cost,
      offer_type,
      approved,
      _id  
     }=this.props.offer
    return (
   
      
     <div className='container m-3 shadow-sm' style={{margin:'auto',padding:0}} >
        <div className='col-12 row'>
         <p className='blue_text col'>{carrier.carrier_name}</p>
         <p className='gray_text col'>{carrier.carrier_address}</p>
         
        </div>
        <div className='col-12 row'>
    <p className='gray_text col-6'>Tel. {carrier.carrier_phone}</p>
         <p className='gray_text col-6'>Mob. </p>
        </div>
        <hr/>
        <div className='col-12 row'>
         <p className='black_text col'>Pickup</p>
         <p className='gray_text col'>{dateformat(date_pickup,'d-m-yyyy')}</p>
    <p className='gray_text col'>{time_pickup}</p>
    <p className='gray_text col'>{offer_type}</p>
          <h3 className='blue_text bold col'>{cost} €</h3>
        </div>
        <div className='col-12 row'>
         <p className='black_text col'>Delivery</p>
         <p className='gray_text col'>{dateformat(date_dropoff,'d-m-yyyy')}</p>
         <p className='gray_text col'>{time_dropoff}</p>
         {approved==false?
         <React.Fragment>
         <button onClick={this.decline_offer} className='nobg_btn_red col'>Decline</button>
         <button onClick={()=>this.accept_offer(this.props.shipment_id,_id)} className='nobg_btn_green col'>{this.state.loading?'Loading...': 'Accept'}</button>
         </React.Fragment>
         :<React.Fragment>
         <p className='black_text col' style={{color:'green',fontWeight:600}}></p>
         <p className='black_text col' style={{color:'green',fontWeight:600}}>Approved</p>
         </React.Fragment>
         }
        </div>
     </div> 
    );
  }
  
}
const mapStateToProps = (state) => {
   return {
     data: state.shipments_reducer,
     carriers_data: state.carriers_reducer,
   }
 }
 const mapDispatchToProps = dispatch => {
   return {
     approve_offer: (id,body,callback) => dispatch(approve_offer_async(id,body,callback)),
   }
 }
 
 export default connect(mapStateToProps, mapDispatchToProps)(OfferCard);