import React, { Component } from "react";

export default class Progressbar extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="progress mt-3" style={{ height: "5px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: this.props.barvalue,
              height: "5px",
              background: "linear-gradient(to right, #0039e6, #00b33c)"
            }}
          ></div>
        </div>
      </React.Fragment>
    );
  }
}
