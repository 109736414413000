import { GET_SHIPMENTS, START_REQUEST, GET_SHIPMENT_BY_ID, ADD_CARRIERS_TO_SHIPMENT, GET_OFFERS,APPROVE_OFFER, FILTER_ADMIN_SHIPMENTS } from "../constants";

const initialstate = {
  shipments: [
  ],
  filtered_shipments:[],
  filter_applied:false,
  shipments_count: null,
  shipment_by_id:null,
  loading:false,
  show_filtered_shipments:false,
  show_all_shipments:true
  };
  
  const shipmnets_reducer = (state = initialstate, action) => {
    if (action.type === GET_SHIPMENTS) {
      return {
        ...state,
       shipments:action.payload,
       shipments_count:action.count,
       loading:false,
       filtered_applied:false,
      };
    }
    if (action.type === GET_SHIPMENT_BY_ID) {
      let shipments = state.shipments; 
      shipments.map(shipment=>{
        if(shipment._id ==action.payload._id){
          shipment = action.payload;
        }
      })

      shipments.push(action.payload)
      return {
        ...state,
        shipments,
      
      };
    }
    if (action.type === GET_OFFERS) {
      let shipments = state.shipments;  
       shipments.map(shipment=>{
         if(shipment._id==action.id){
           shipment.offers = action.payload;
         }
       }) 
      return {
        ...state,
        shipments
      };
    }
    if (action.type === APPROVE_OFFER) {
      let shipments = state.shipments;  
       shipments.map(shipment=>{
         if(shipment._id==action.shipment._id){
          shipment=action.shipment;
         }
       }) 
      return {
        ...state,
        shipments
      };
    }
    if (action.type === START_REQUEST) {
      return {
        ...state,
       loading:true
      };
    }
    



      if (action.type === ADD_CARRIERS_TO_SHIPMENT) {
        let shipments = state.shipments;
        shipments.map(shipment=>{
          if(shipment._id==action.id){
            shipment= action.shipment
          }
        })
        console.log(shipments);
        return {
          ...state,
          shipments
        };
    }

    if(action.type===FILTER_ADMIN_SHIPMENTS){
      console.log(action.filtered_shipments)
      return {...state,filtered_shipments:action.filtered_shipments,filter_applied:true}          
      }
    return state;
  };
  
  export default shipmnets_reducer;
  