import React, { Component } from "react";
import { FaLaravel } from "react-icons/fa";
import "../Styles/Customer.css";
import InputField from "./InputField";
import { connect } from 'react-redux'
import { filter_carriers } from "../Redux/actions/carriers_actions";
import { filter_customers } from "../Redux/actions/customer_actions";

 class FilterModule extends Component {
  constructor(props){
    super(props)
    this.state={

    }
  }
  filter=(e)=>{
    e.preventDefault()
    let data = this.props.data;
    this.props.Label_Info.map(label=>{
     data =data.filter(d=> {
       if(this.state[label.field_name]? d[label.field_name] ? d[label.field_name].toString().toLowerCase().includes(this.state[label.field_name].toString().toLowerCase()):true:true)
       return d;
    })
        
    })
    if(this.props.type.toLowerCase()=='customer'){
      this.props.filter_customers(data)
    }else{
      this.props.filter_carriers(data)
    }
  }
  changeHandler=(e)=>{
    this.setState({[e.target.name]:e.target.value})
  }

  render() {
    const {
      changeHandler,
      title,
      state,
      searchRecord,
      Label_Info
    } = this.props;

    return (
      <div className='col-8 d-flex flex-column bg-white shadow-sm justify-content-center pb-4 p-4' >
        <form onSubmit={this.filter}>
            <h3 className='black_text bold'>{title}</h3>
          {Label_Info.map(label=>{
           return <InputField
            name={label.field_name}
            label={label.label}
            label_text={label.label_text}
            changeHandler={this.changeHandler}
            value={this.state[label.field_name]}
            placeholder={label.placeholder}
          />
          })}
        <button className="btn btn-primary w-100 pb-2" type='submit'>Search</button>
        </form>
      </div>
    );
  }
}
const mapStateToProps=(state)=>{
  return{
  }
}
const mapDispatchToProps =dispatch=>{
  return {
    filter_carriers:(filtered_data)=> dispatch(filter_carriers(filtered_data)),
    filter_customers:(filtered_data)=> dispatch(filter_customers(filtered_data)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FilterModule);