import React, { Component } from 'react'
import { connect } from 'react-redux'
import CarrierCustomerData from '../Components/ShipmentInformation/InfoRepresentation/CarrierCustomerData';
import Datetime from '../Components/ShipmentInformation/InfoRepresentation/Datetime';
import ShipmentAttributes from '../Components/ShipmentInformation/InfoRepresentation/ShipmentAttributes';
import ShipmentPhotos from '../Components/ShipmentInformation/InfoRepresentation/ShipmentPhotos';
import Price from '../Components/ShipmentInformation/InfoRepresentation/Price'

import StateCard from './StateCard';

export class ShipmentInfoCard extends Component {
   
    first_capital(string) 
    {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    render() {
        const shipment = this.props.shipment    
        return (
          <div>
               {shipment!=null ?(
                <div className=" pt-3 p-3 d-flex flex-column">
                    <div className="container">
                      <div className="float-left">
                      <div className='d-block'>
                        <span className="offer-description ">ID:</span>
                        <span className="gray_text ml-2 ">{shipment._id}</span>
                        </div>
                      <div className='d-block'>
                        <span className="offer-description ">Description:</span>
                        <span className="gray_text ml-2 ">{shipment.shipment_description}</span>
                        </div>
                        <div className='d-block'>
                        <StateCard state={shipment.shipment_status} /> 
                      </div>
                        <div className='d-block'>
                        <span className="black_text">Notes :</span>
                        <span className="gray_text ml-2 ">{shipment.shipment_notes}</span>
                        </div>
                      </div>
                      <div className="float-right">
                        <Price price={shipment.price} />
                      </div>
                    </div>
                  <hr />
                    <CarrierCustomerData shipment={shipment} />
                  <hr />
                  <Datetime 
                  pickup_location={shipment.pickup_location}
                  dropoff_location={shipment.dropoff_location}
                  pickup_date={shipment.pickup_date}
                  pickup_time={shipment.pickup_time}
                  dropoff_date={shipment.dropoff_date}
                  dropoff_time={shipment.dropoff_time}
                  />
               
                  <hr />
                  {/* delivery description */}
                  <ShipmentAttributes shipment_attributes={shipment.shipment_attributes} />
                  <hr />
                  {/* comment box */}
                  <div className="form-group mt-4">
                    <ShipmentPhotos pics={shipment.pics} />
                  </div>
                </div>
          ):<h4 style={{margin:'auto'}}>Loading</h4>}  
          </div> 
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentInfoCard)
