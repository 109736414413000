import React,{ useEffect,useState }from 'react'
import NewShipment from '../../NewShipment/NewShipment'
import {
    useParams,
  } from "react-router-dom";
import {useQuery} from 'react-query'
import { getData } from '../../../globals';

export default function EditShipment({}) {
    let { id } = useParams();
    const [shipment, setshipment] = useState({})
   
    const {data, isLoading} = useQuery(['shipment',id],()=>getData(`crud/shipments?_id=${id}`))

    useEffect(() => {
       if(data){
           setshipment(data.data[0])
       }
    }, [data])

   
    return (
        <NewShipment editMode={true} shipment={shipment}  />
    )
}
