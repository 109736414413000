import React, { Component } from "react";
import "../Styles/Card.css";
import Images from "../Images";
import Progressbar from "./Progressbar";
import StateCard from './StateCard'
import { Button, ButtonGroup } from "@chakra-ui/react"
import {Link}from 'react-router-dom'
import uparrow from '../Pics/up_icon.png'
import downarrow from '../Pics/down_icon.png'
import Row from '../Common/Layout/Row'
import { useMediaQuery,LinkBox,Box,Heading,LinkOverlay,Text } from "@chakra-ui/react"
import Column from "./Layout/Column";
var dateFormat = require('dateformat');

export default function Card({item}) {
  const [lessThan600px] = useMediaQuery("(max-width: 700px)")
  const {
    _id,
    shipment_attributes,
    shipment_status,
    progressvalue,
    pickup_location,
    pickup_date,
    dropoff_location,
    dropoff_date,
    price,
    pickup_time,
    dropoff_time,
    shipment_description,
    createdAt
  } = item;

  if(lessThan600px){
    return (
      <Link to={`/shipmentinfo/${_id}`}>
      <LinkBox 
      bgColor='white'  
      maxW="100%" 
      p="5" 
      borderWidth="1px" 
      rounded="md" 
      marginBottom={2}>
        <Row style={{justifyContent:'flex-start',alignItems:'center'}}>
         <p className="blueandbold">{shipment_description}</p>
         <p className="gray_text ml-2">{shipment_attributes.packing} </p>
         </Row>
         <StateCard state = {shipment_status} />
         <p className='black_text '>{dateFormat(createdAt,'dd-mm-yyyy hh:mm')}</p>
         {/* Pickup Location DateTime */}
         <Row style={{marginTop:10}}>
            <img
              src={uparrow}
              className="img-fluid d-inline"
              alt="arrowup"
              style={{ width: "1.5em", height: "1.5em",marginRight:10 }}
            ></img>
            <Column style={{ alignItems:'flex-start'}}>
            <p className="blueandbold text-center addresssize ">{pickup_location.address} </p>
            <Row style={{ justifyContent:'flex-start'}}>
            {pickup_date&& <p className="gray_text  bold ">{dateFormat(pickup_date.toLocaleString(),'d-m-yyyy')}</p>}
            {pickup_time&& <p className="gray_text  bold ml-4 ">{pickup_time}</p>}
            </Row>
            </Column>
          </Row>
            {/* Pickup Location DateTime */}
            <Row style={{marginTop:10}}>
            <img
              src={downarrow}
              className="img-fluid d-inline"
              alt="arrowup"
              style={{ width: "1.5em", height: "1.5em",marginRight:10 }}
            ></img>
            <Column style={{ alignItems:'flex-start'}}>
            <p className="blueandbold text-center addresssize ">{dropoff_location.address} </p>
            <Row style={{ justifyContent:'flex-start'}}>
            {pickup_date&& <p className="gray_text  bold ">{dateFormat(dropoff_date.toLocaleString(),'d-m-yyyy')}</p>}
            {pickup_time&& <p className="gray_text  bold ml-4 ">{dropoff_time}</p>}
            </Row>
            </Column>
          </Row>
          {shipment_status!='WAITING'?<p className="blue_text bold ">{price} €</p> :<p className="blue_text bold ">---$</p> }
         
    </LinkBox>
    </Link>
    )

  }else{
  return (
     <div className="bg-white p-1 rounded mb-2 shadow-sm mt-2">
        <div className="row py-3">
          <div className="col-md-2 col-sm-12">
              <p className="blueandbold">{shipment_description}</p>
              <p className="gray_text">{shipment_attributes.packing} </p>
              <StateCard state = {shipment_status} />
              <p className='black_text mt-3'>{dateFormat(createdAt,'dd-mm-yyyy hh:mm')}</p>
          </div>
          <div className="col-md-4 col-sm-12">
            <Row>
            <img
              src={uparrow}
              className="img-fluid d-inline"
              alt="arrowup"
              style={{ width: "2em", height: "2em" }}
            ></img>
            <span className="blueandbold text-center addresssize ">
              &nbsp; {pickup_location.address}
            </span>
            </Row>
          <Row style={{ marginTop:'1em'}}>
           {pickup_date&& <p className="gray_text  bold ml-4 ">{dateFormat(pickup_date.toLocaleString(),'d-m-yyyy')}</p>}
           {pickup_time&& <p className="gray_text  bold ml-4 ">{pickup_time}</p>}
          </Row>

          </div>
          <div className="col-md-4 col-sm-12">
          <Row>
            <img
              src={downarrow}
              className="img-fluid d-inline"
              alt="arrowdown"
              style={{ width: "2em", height: "2em" }}
            ></img>
            <span className="blueandbold addresssize ">
              &nbsp; {dropoff_location.address}
            </span>
            </Row>
            <Row style={{ marginTop:'1em'}}>
           {dropoff_date && <p className="gray_text  bold ml-4">{dateFormat(dropoff_date.toLocaleString(),'d-m-yyyy')}</p>}
           {dropoff_time&& <p className="gray_text  bold ml-4 ">{dropoff_time}</p>}
          </Row>
          </div>
          <div className="col-md-2 col-sm-12">
            {shipment_status!='WAITING'?<p className="blue_text mt-5 bold ">{price} €</p> :<p className="blue_text mt-5 bold ">---$</p> }
           <Link to={`/shipmentinfo/${_id}`}>
           <Button colorScheme="blue">Open</Button>
           </Link>
          </div>
        </div>
      </div>
    
  )
}
    
}

