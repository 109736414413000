import React,{useEffect} from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Spinner,
    Box,
    Text,
    Button,
    useToast,
    Tooltip,
  } from "@chakra-ui/react"
import {useQuery,useQueryClient,useMutation} from 'react-query'
import { getData, postData, putData } from '../../../globals'
import Row from '../../../Common/Layout/Row'
import Column from '../../../Common/Layout/Column'
var dateFormat = require('dateformat');
export default function Offers({isOpen,onClose,shipment_id}) {

  const queryClient = useQueryClient()
  const toast = useToast()
  const {data, isLoading} = useQuery(['offersbyshipment',shipment_id],()=>getData(`crud/offers?shipment_id=${shipment_id}`),{
    initialData: () => {
     return queryClient.getQueryData(['offersbyshipment',shipment_id])
      }
  })
   //Accept Offer 
   const acceptOffer= useMutation((body)=>postData(body,`admin/shipments/${shipment_id}/offers`),{
    onSuccess: (data)=>{
        toast({
            title: "Success", 
            description: "Offer Accepted",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
      queryClient.invalidateQueries(['offersbyshipment',shipment_id])
    }
  })

   
  useEffect(() => {
   console.log(data) 
  }, [data])

    return (
        <Drawer
        isOpen={isOpen}
        placement="left"
        size='lg'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent bgColor='rgba(250,250,250,1)'>
          <DrawerCloseButton />
          <DrawerHeader>Offers</DrawerHeader>

          <DrawerBody>
            {isLoading ? <Spinner/>
            :
            data &&
            data.data.map(offer=>{
              return <OfferCard acceptOffer={acceptOffer} offer={offer}/>
            })
            }

          </DrawerBody>

         
        </DrawerContent>
      </Drawer>
    )
}


const OfferCard =({offer,acceptOffer})=>{

  const { approved ,carrier_populated,_id} =offer

  return (
    <Box bgColor={`${approved? "rgba(0,255,0,.2)":"white"}`} padding='1em' margin='.5em' rounded >
      {(Array.isArray(carrier_populated)&& carrier_populated.length>0)?
      <>
      <Text>Carrier</Text>
      <Row style={{flexWrap:'wrap'}}>
      <Text color='gray.500' marginRight='1em'>{carrier_populated[0].carrier_name}</Text>
      <Text color='gray.500' marginRight='1em'>{carrier_populated[0].carrier_email}</Text> 
      <Text color='gray.500'>{carrier_populated[0].carrier_phone}</Text> 
      </Row>
      </>
      :null}
     

      <Text>Pickup</Text>
      <Row>
      <Text color='gray.500' marginRight='1em'>{dateFormat(offer.date_pickup.toLocaleString(),'d-m-yyyy')}</Text>
      <Text color='gray.500'>{offer.time_pickup}</Text>
      </Row>
      <Text>Dropoff</Text>
      <Row>
      <Text color='gray.500' marginRight='1em'>{dateFormat(offer.date_dropoff.toLocaleString(),'d-m-yyyy')}</Text>
      <Text color='gray.500'>{offer.time_dropoff}</Text>
      </Row>
      <Text fontSize='2xl' marginBottom='1em' color='blue'>{offer.cost} €</Text>
       {approved ?<>
        <Text color='green'>Accepted</Text> 
        <Tooltip hasArrow label="Double Click to Delete" bg="gray.300" color="black">
        <Button size='sm' marginRight='1em' onDoubleClick={()=>acceptOffer.mutate({offerid:_id,approval:false})} isLoading={acceptOffer.isLoading} colorScheme='red'> Decline</Button> 
        </Tooltip>
        </>
        : 
       <>
       <Tooltip hasArrow label="Double Click to Delete" bg="gray.300" color="black">
        <Button size='sm' marginRight='1em' onDoubleClick={()=>acceptOffer.mutate({offerid:_id,approval:false})} isLoading={acceptOffer.isLoading} colorScheme='red'> Decline</Button> 
       </Tooltip>
       <Button size='sm' onClick={()=>acceptOffer.mutate({offerid:_id,approval:true})} isLoading={acceptOffer.isLoading} colorScheme='green'> Accept</Button> 
       </>
}

    </Box>
  )


}