export const GET_SHIPMENTS = 'GET_SHIPMENTS'
export const GET_CUSTOMERS= 'GET_CUSTOMERS'
export const GET_CARRIERS= 'GET_CARRIERS'
export const START_REQUEST = 'START_REQUEST'
export const FAIL_REQUEST= 'FAIL_REQUEST'
export const ADD_CARRIER = 'ADD_CARRIER'
export const GET_SHIPMENT_BY_ID= 'GET_SHIPMENT_BY_ID'
export const ADD_CARRIERS_TO_SHIPMENT= 'ADD_CARRIERS_TO_SHIPMENT'
export const GET_OFFERS= 'GET_OFFERS'
export const APPROVE_OFFER= 'APPROVE_OFFER'
export const GET_CARRIER_CUSTOMER_CHAT = 'GET_CARRIER_CUSTOMER_CHAT'

//STRIPE
export const GET_CARRIERS_PAYMENT= 'GET_CARRIERS_PAYMENT'
export const GET_SELECTED_CARRIERS_PAYMENT = 'GET_SELECTED_CARRIERS_PAYMENT'
export const GENERATE_INVOICE = 'GENERATE_INVOICE'

//CUSTOMERS
export const ADD_CUSTOMER_FAIL= 'ADD_CUSTOMER_FAIL'
export const ADD_CUSTOMER_SUCCESS= 'ADD_CUSTOMER_SUCCESS'
export const ADD_CUSTOMER_START= 'ADD_CUSTOMER_START'

export const EDIT_CUSTOMER_FAIL= 'EDIT_CUSTOMER_FAIL'
export const EDIT_CUSTOMER_SUCCESS= 'EDIT_CUSTOMER_SUCCESS'
export const EDIT_CUSTOMER_START= 'EDIT_CUSTOMER_START'

export const DELETE_CUSTOMER_FAIL= 'DELETE_CUSTOMER_FAIL'
export const DELETE_CUSTOMER_SUCCESS= 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_START= 'DELETE_CUSTOMER_START'

//CARRIERS
export const ADD_CARRIER_FAIL= 'ADD_CARRIER_FAIL'
export const ADD_CARRIER_SUCCESS= 'ADD_CARRIER_SUCCESS'
export const ADD_CARRIER_START= 'ADD_CARRIER_START'

export const EDIT_CARRIER_FAIL= 'EDIT_CARRIER_FAIL'
export const EDIT_CARRIER_SUCCESS= 'EDIT_CARRIER_SUCCESS'
export const EDIT_CARRIER_START= 'EDIT_CARRIER_START'

export const DELETE_CARRIER_FAIL= 'DELETE_CARRIER_FAIL'
export const DELETE_CARRIER_SUCCESS= 'DELETE_CARRIER_SUCCESS'
export const DELETE_CARRIER_START= 'DELETE_CARRIER_START'
export const ADD_NEW_SHIPMENT_CUSTOMER = 'ADD_NEW_SHIPMENT_CUSTOMER'
export const EDIT_SHIPMENT_CUSTOMER = 'EDIT_SHIPMENT_CUSTOMER'

//FILTERS
export const FILTER_ADMIN_SHIPMENTS = 'FILTER_ADMIN_SHIPMENTS'
export const FILTER_CUSTOMERS= 'FILTER_CUSTOMERS'
export const FILTER_CARRIERS= 'FILTER_CARRIERS'

export const GET_NOTIFICATIONS_FAIL= 'GET_NOTIFICATIONS_FAIL'
export const GET_NOTIFICATIONS_SUCCESS= 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_START= 'GET_NOTIFICATIONS_START'
