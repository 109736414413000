import React from 'react'
import Crud from '../../Common/Crud/Crud'
import NavBar from '../../Common/NavBar'
import * as yup from 'yup';
export default function Offers() {
    return (
        <div>
           <NavBar/>
         <Crud
          queryString='offers'
           getUrl="crud/offers"
           action="OFFERS"
           title='Offers'
           reducer='offers_reducer'
           reduxSelector='offers'
           schema={[
            {
            label:"Carrier",dbvalue:"carrier",show:{get:true,add:true,edit:true},
            populated:['carrier_name','carrier_email'],
            type:"select",
            getUrl:"crud/carriers",
            queryString:'carriers',
            selectLabel1:'carrier_name',
            selectLabel2:'carrier_email'
            },
            {label:"Shipment",dbvalue:"shipment_id",show:{get:false,add:true,edit:true},
            type:"select",
            getUrl:"crud/shipments",
            queryString:'shipments',
            selectLabel1:'shipment_description',
            selectLabel2:'_id'

            },
            {label:"id",dbvalue:"_id",show:{get:false,add:false,edit:true}},
            {label:"Cost",dbvalue:"cost",type:'number',show:{get:true,add:true,edit:true}},
            {
                label:"Date Pickup",
                dbvalue:"date_pickup",
                type:'date',
                show:{get:true,add:true,edit:true}
             },
             {
                label:"Date Dropoff",
                dbvalue:"date_dropoff",
                type:'date',
                show:{get:true,add:true,edit:true}
             },
             {
                 label:"Approved",
                dbvalue:"approved",
                type:"boolean",
                show:{get:true,add:true,edit:true}
            

             }
             ]}
         formValues={{}}
         validationSchema = { yup.object().shape({
            //carrier_name: yup.string().required(),
            //carrier_email: yup.string().email().required(),
            //carrier_phone: yup.string().required(),
            //carrier_password:yup.string().required()
          })}
           />
        </div>
    )
}
