
const initialstate = {
  offers:[],
  filter_applied:false,
  filtered_records:[]
  };
  
  const offers_reducer = (state = initialstate, action) => {
  
  if(action.type==="GET_OFFERS_SUCCESS"){ return {...state,offers:action.payload.data.data,filter_applied:false}}
  if(action.type==="FILTER_OFFERS_SUCCESS"){ return {...state,filtered_records:action.payload.data.data,filter_applied:true}}
  if(action.type==="ADD_OFFER_SUCCESS"){ 
    const offers = state.offers.concat(action.payload.data.data)
    return {...state,offers}}
   if(action.type==="DELETE_COFFER_SUCCESS"){ 
     const offers =state.offers.filter(element => element._id !== action.payload.data.id)
     return {...state,offers}}
  if(action.type==="EDIT_OFFER_SUCCESS"){
    const offers =state.offers.map(element => element._id === action.payload.data.data._id? action.payload.data.data :element) 
    return {...state,offers}
  }
  if(action.type==='RESET_FILTERS_OFFERS'){
    return {...state,filter_applied:false,filtered_records:[]}
  }
  

    return state;
  };
  
  export default offers_reducer;
  