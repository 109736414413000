import React from 'react';
import { Badge } from "@chakra-ui/react"
 
function StateCard({state}) {

   let mystate=String(state).toLowerCase();
   mystate = mystate.charAt(0).toUpperCase() + mystate.slice(1);
  
   switch (state) {
      case 'WAITING':
         return <Badge colorScheme='yellow'>{state}</Badge>
         break;
      case 'UPCOMING':
         return <Badge colorScheme='purple'>{state}</Badge>

      break;
      case 'INPROGRESS':
         return <Badge colorScheme='teal'>{state}</Badge>
      break;
      case 'COMPLETED':
         return <Badge colorScheme='green'>{state}</Badge>

        break;   
      default:
         break;
   }
 
}
 
export default StateCard;