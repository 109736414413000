export const  customer_input_field=[
{name:'customer_name',label:'Full Name',type:'text'},
{name:'customer_email',label:'Email',type:'email'},
{name:'customer_mobilenum',label:'Mobile Number',type:'number'},
{name:'customer_password',label:'Password',type:'password'},
{name:'customer_password_re',label:'Re enter password',type:'password'},
]

export const  customer_input_field_edit=[
  {name:'customer_name',label:'Full Name',type:'text'},
  {name:'customer_email',label:'Email',type:'email'},
  {name:'customer_mobilenum',label:'Mobile Number',type:'number'},

  ]

export const  carrier_add_input_field=[
{name:'carrier_name',label:'Full Name',type:'text'},
{name:'carrier_email',label:'Email',type:'email'},
{name:'carrier_phone',label:'Mobile Number',type:'number'},
{name:'carrier_password',label:'Password',type:'password'},
{name:'carrier_password_re',label:'Re enter password',type:'password'},
]
export const  carrier_input_field_edit=[
  {name:'carrier_name',label:'Full Name',type:'text'},
  {name:'carrier_email',label:'Email',type:'email'},
  {name:'carrier_phone',label:'Mobile Number',type:'number'},
  ]
export const  split_later=[
  {name:'carrier_name',label:'Full Name',type:'text'},
]
export const  split_now=[
  {name:'carrier_name',label:'Full Name',type:'text'},
]

export const filter = (Label_Info,data,state) => {
    let r=0;
    let count=0
    Label_Info.map(label=>{
      if(state[label.field_name]=='' || state[label.field_name]==null){
         count+=1
      }
    }) 
    if(count==Label_Info.length){
     return { allshow: true, filtershow: false};
    }else{
      const record = data.filter(item => {
      r=false
      Label_Info.map(label=>{
        if(state[label.field_name]!='' || state[label.field_name]!=null){
           if(String(item[label.field_name]) === String(state[label.field_name])){
            r+=1;
            
           }
        }
      })
      return r==Label_Info.length-count? true :false
    });
    return { allshow: false, filtershow: true, filterrecord: record };
  } 
}
