import React,{useEffect} from 'react'
import Row from '../../../Common/Layout/Row';
import { transform_shipment_attributes } from '../../../globals';
import { Divider } from '@chakra-ui/react';
export default function ShipmentAttributes({shipment_attributes}) {
  useEffect(() => {
    console.log("Shipment Attributes ",shipment_attributes)
  }, [shipment_attributes])

  const renderObjectOfAttributes = (obj,index=0,isArray=false) =>{
    return  <>

    <Row style={{flexWrap:'wrap'}}>
     <p  style={{marginRight:'1em'}}>{index}.</p>
     {Object.keys(transform_shipment_attributes(obj)).map((attribute,index)=>{
      let  key =attribute;
           return(
             <div className={`d-flex flex-column`} style={{paddingRight:15}} >
             <span className="black_text">{attribute}</span>
             <span className="gray_text">{transform_shipment_attributes(obj)[key]}</span>
             </div>
           )
       })}
    </Row>
    {isArray && <Divider/> }

    </>
  }
  return (
      <>
      <p className='blue_text'>Shipment Attributes</p>
     
        {(Array.isArray(shipment_attributes))?
          shipment_attributes.map((attr,index)=>renderObjectOfAttributes(attr,index+1,index<shipment_attributes.length-1 ?true:false))
          :typeof shipment_attributes === 'object' ?
          renderObjectOfAttributes(shipment_attributes)
          :null}
    
    </>
  )
}