const initialstate = {
  auth_Token: "",
  Admin: {},
  //any other customer object can be added here and later used
};

const adminReducer = (state = initialstate, action) => {
  if (action.type === "LOGIN_ADMIN") {
    return {
      ...state,
      auth_Token: action.data.authToken,
      Customer: { ...action.data.admin },
    };
  }

  return state;
};

export default adminReducer;
