import axios from 'axios';
import {api_callback, URL} from '../../globals'
import { get_carriers_action,
 add_carrier_fail, 
 add_carrier_success,
 add_carrier_start, 
 delete_carrier_start,
 delete_carrier_success,
 get_carriers_payment_action,
 get_selected_carriers_payment_action,
 delete_carrier_fail, 
 edit_carrier_success, 
 edit_carrier_fail,
 edit_carrier_start, 
 start_request,
 generate_invoice_action} 
 from './carriers_actions';



export const get_carriers_async=()=>{
    return (dispatch)=>{
       // dispatch(start_request())
        axios
         .get(`${URL}admin/carriers/`)
         .then((response)=> {
            dispatch(get_carriers_action(response.data.data.allCarriers,null));
            console.log(response.data.data.allCarriers);
            
            //AddDriverSucess();
         })
         .catch(function(error) {
           if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log("Error", error.message);
            console.log("error 1");
           } else if (error.request) {
            console.log(error.request);
            } else {
             console.log("Error", error.message);
            }
             console.log(error.config);
              });
          };;
        

    }

   
      export const add_carrier_async=(body,callback)=>{
      
         return (dispatch)=>{
          dispatch(add_carrier_start())
             axios
              .post(`${URL}admin/carriers`,body)
              .then((response)=> {
                 dispatch(add_carrier_success(response.data.carrier));
                 console.log(response);
                 callback(true)
   
              })
              .catch(function(error) {
                if (error.response) {
                 dispatch(add_carrier_fail(error.response.data.message)) 
                 callback(false)
                 console.log(error.response.data);
                 console.log(error.response.status);
                 console.log(error.response.headers);
                 console.log("Error", error.message);
                 console.log("error 1");
                } else if (error.request) {
                 console.log(error.request);
                 } else {
                  console.log("Error", error.message);
                 }
                  console.log(error.config);
                   });
               };;
             
     
         }
   
         export const delete_carrier_async=(id,callback)=>{
         
            return (dispatch)=>{
             dispatch(delete_carrier_start())
                axios
                 .delete(`${URL}admin/carriers/${id}`,)
                 .then((response)=> {
                    dispatch(delete_carrier_success(id));
                    console.log(response);
                    api_callback(true,'Success','Carrier Deleted Succesfully!')
                    callback(true)
      
                 })
                 .catch(function(error) {
                   if (error.response) {
                    dispatch(delete_carrier_fail(error.response.data.message)) 
                    callback(false)
                    api_callback(false,'Fail','Fail Occured')
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    console.log("Error", error.message);
                    console.log("error 1");
                   } else if (error.request) {
                    console.log(error.request);
                    } else {
                     console.log("Error", error.message);
                    }
                     console.log(error.config);
                      });
                  };;
                
        
            }
            export const edit_carrier_async=(body,callback)=>{
               console.log(body)
               return (dispatch)=>{
                   dispatch(edit_carrier_start())
                  
                   axios
                    .put(`${URL}admin/carriers`,body)
                    .then((response)=> {
                       dispatch(edit_carrier_success(response.data.carrier));
                       console.log(response);
                       api_callback(true,'Success','Carrier Data Changed Succesfully')
                       callback(true)
         
                    })
                    .catch(function(error) {
                    
                      if (error.response) {
                       dispatch(edit_carrier_fail(error.response.data.error_message)) 
                       callback(false)
                       api_callback(false,'Fail','Carrier Data Change Failed')
                       console.log(error.response.data);
                       console.log(error.response.status);
                       console.log(error.response.headers);
                       console.log("Error", error.message);
                       console.log("error 1");
                      } else if (error.request) {
                       console.log(error.request);
                       } else {
                        console.log("Error", error.message);
                       }
                        console.log(error.config);
                         });
                     };;
                  
           
               }
           
               export const get_carriers_payment_async = () => {
                  return (dispatch) => {
                     // dispatch(start_request())
                     axios
                        .get(`${URL}admin/carriers/`)
                        .then((response) => {
                           dispatch(get_carriers_payment_action(response.data.data.allCarriers, null));
                           //  console.log(response.data.data.allCarriers);
               
                           //AddDriverSucess();
                        })
                        .catch(function (error) {
                           if (error.response) {
                              console.log(error.response.data);
                              console.log(error.response.status);
                              console.log(error.response.headers);
                              console.log("Error", error.message);
                              console.log("error 1");
                           } else if (error.request) {
                              console.log(error.request);
                           } else {
                              console.log("Error", error.message);
                           }
                           console.log(error.config);
                        });
                  };;
               }
               
               export const get_selected_carriers_payment_async = (carrierId, monthId) => {
                  console.log('get_selected_carriers_payment_async called', carrierId, monthId)
                  return (dispatch) => {
                     // dispatch(start_request())
                     axios
                        .get(`${URL}admin/filter-carriers/${carrierId}/${monthId}`)
                        .then((response) => {
                           dispatch(get_selected_carriers_payment_action(response.data.data.payments, null));
                            console.log(response.data.data.payments);
               
                           //AddDriverSucess();
                        })
                        .catch(function (error) {
                           if (error.response) {
                              console.log(error.response.data);
                              console.log(error.response.status);
                              console.log(error.response.headers);
                              console.log("Error", error.message);
                              console.log("error 1");
                           } else if (error.request) {
                              console.log(error.request);
                           } else {
                              console.log("Error", error.message);
                           }
                           console.log(error.config);
                        });
                  };;
               }

               export const generate_invoice_async = (data) => {
                  console.log("action",data);
               return (dispatch) => {
                  dispatch(start_request())
                  axios
                     .post(`${URL}stripe/invoice-saved`, data)
                     .then((response) => {
            
                        console.log(response);
                        if(response.data.cod =='true')
                        {
                           alert('Invoice genrated successfully!')
                        }
                        else
                        {
                           alert('Invoice already genrated for this month')
                        }
            
                        window.location.href="/Payment"
                        dispatch(generate_invoice_action(response.data.data.newcarrier));
                        //AddDriverSucess();
                     })
                     .catch(function (error) {
                        if (error.response) {
                           console.log(error.response.data);
                           console.log(error.response.status);
                           console.log(error.response.headers);
                           console.log("Error", error.message);
                           console.log("error 1");
                        } else if (error.request) {
                           console.log(error.request);
                        } else {
                           console.log("Error", error.message);
                        }
                        console.log(error.config);
                     });
               };
            }