import React, { Component,Fragment } from "react";
import '../Styles/Shipments.css'
import ReactModal from 'react-modal';
import { connect } from 'react-redux'
import { get_shipment_by_id_async, get_offers_async, add_carriers_to_shipment, add_carriers_to_shipment2, delete_shipment_async } from "../Redux/actions/shipment_actions_creators";
import { ShipmentInfoCard } from "../Common/ShipmentInfoCard";
import { get_carriers_async } from '../Redux/actions/carriers_actions_creators'
import socketIOClient from "socket.io-client";
import NoSplitModal from '../Common/NoSplitModal'
import SplitModal from '../Common/SplitModal'
import CustomerChatModal from '../Shipment/Chat/CustomerChatModal'
import OffersModal from '../Common/OffersModal'
import 'react-chat-widget/lib/styles.css';
import NavBar from "../Common/NavBar";
import L from 'leaflet'
import { withRouter } from "react-router-dom";
import { Map, TileLayer, Marker, Popup }  from 'react-leaflet';
import { api_callback, URL, URL2 } from "../globals";
import { add_carrier_to_shipment } from "../Services/shipment_services";
import Shipments from "../Shipment/Shipments";
import { Button, ButtonGroup } from "@chakra-ui/react"
import truckMarkerIcon from "../Pics/delivery-truck.png"
import { HStack } from "@chakra-ui/react";
import Row from "../Common/Layout/Row";
import Offers from "../Components/ShipmentInformation/Offers/Offers";
import AssignCarrier from "../Components/ShipmentInformation/AssignCarrier/AssignCarrier";

type Position = [number, number]

type Props = {|
  content: string,
  position: Position,
|}

type MarkerData = {| ...Props, key: string |}

const add_carrier_to_shipment_callback=(success)=>{
  if(!success){
    api_callback(false,"Error","Error Occured")
   return false;
  }
  window.location.reload();
}
 const truckIcon = new L.Icon({
  iconUrl:truckMarkerIcon,
  //iconRetinaUrl: require("../assets/pointerIcon.svg"),
  iconAnchor: [0, 0],
  popupAnchor: [10, -44],
  iconSize: [45, 45],
});
const MyPopupMarker = ({ content, position,availability,shipment_id }: Props) => {
  
  return(
  <Marker icon={truckIcon} position={position}>
    <Popup>
      <h4>Driver Information</h4>
      <br/>
      <b>Id : {content?._id}</b>
      <br/>
      <b> Email : {content?.driver_email}</b>
      <br/>
      <b>Capacity: {availability} %</b>
      <p className='gray_text'>Please Double-Click</p>
      <button onClick={()=>add_carriers_to_shipment2(shipment_id,{carriers:[content.carrier_id]},add_carrier_to_shipment_callback)} className='btn btn-primary'>Add to Shipment</button>
      </Popup>
  </Marker>
  )
}

const MyMarkersList = ({ markers,shipment_id }: { markers: Array<MarkerData> }) => {
  const items = markers.map(({ key, ...props }) => (
    <MyPopupMarker key={key} {...props} shipment_id={shipment_id} />
  ))
  return <Fragment>{items}</Fragment>
}

type State = {
  markers: Array<MarkerData>,
}


class ShipmentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map_center:[37.983810, 23.727539],
      show_map:false,
      availability_socket_connected:false,
      offersModalShow:false,
      modals_visibility: [
        false,//split
        false,//nosplit
        false,//offers
        false,//customer chat
      ],
      carriers_selected: [],
      split_modal_later: null,
      nosplit_modal_visibility: false,
      assignCarrierModalShow:false,
      shipment_id: null,
      shipment: null,
	  markers: [
      
    ],
    };
  }
  
  componentWillMount() {
    this.setState({ shipment_id: this.props.match.params.id })
    this.props.get_carriers();
    const shipment = this.props.data.shipments.find(shipment=>shipment._id===this.props.match.params.id);
    if(!shipment){
      this.props.get_shipment_by_id(this.props.match.params.id, this.get_shipment_by_id_callback)
    }else{
      this.setState({ shipment})
    }
   
  
   
  }
  componentDidUpdate=()=>{
    if(this.state.show_map && this.state.availability_socket_connected==false){
      this.handle_availability();
    }
  }


  handle_availability=()=>{
    const socket = socketIOClient(`${URL2}availabililty_location`);
    socket.on('connect', () => {
      this.setState({availability_socket_connected:true})
    });
     socket.on('get_drivers_location',(obj)=>{
       console.log(obj)
       //update markers
       let markers = this.state.markers;
       const found_marker = markers.find(marker=> marker.key === obj.driver_id)
       if(found_marker){
         if(obj.availability>0){//availability>0
         markers.map(marker=>{
           if(marker===found_marker){
             found_marker.position=obj.location
             found_marker.content = obj.data
             found_marker.availability = obj.availability
            return found_marker 
           }else{
             return marker
           }
         })
        }else{ //availability < 0
           markers = markers.filter(marker=>marker.key != obj.driver_id)
        }
       }else{
        if(obj.availability>0){
         markers.push({key:obj.driver_id,position:obj.location,content:obj.data,availability:obj.availability})
         }
       }
       console.log(markers)
       this.setState({markers})
     })
  }
  
  get_shipment_by_id_callback = (success) => {
    if (success) {
      const shipment= this.props.data.shipments.find(shipment=>shipment._id==this.props.match.params.id);
      this.setState({ shipment})
    } else {
    }
  }
  handleDelete = ()=>{
   delete_shipment_async(this.state.shipment_id,(success)=>{
     if(!success){return;}
     api_callback(true,"Success","Deleted Succesfully")
     this.props.history.push('/Shipments')
   }) 
  }
 
  
  render() {
    const shipment = this.state.shipment;
    if(shipment!=null){
   // console.log(shipment)
    }
    return (
      <React.Fragment>
	      <NavBar/>
        
        <Offers
          isOpen={this.state.offersModalShow}
          onClose={()=> this.setState({offersModalShow:false})}
          shipment_id={shipment?._id}
        />
        <AssignCarrier
        isOpen={this.state.assignCarrierModalShow}
        onClose={()=> this.setState({assignCarrierModalShow:false})}
        shipment_id={shipment?._id}
        selected_carriers={shipment?.carriers_selected_to_offer}
        />
       

        <div className="col-md-6 col-sm-12 mb-5 mt-2 " style={{ maxHeight: '90vh',float:'left',padding:0,backgroundColor:'white' }} >
            <div className="p-2 pt-4" style={{ maxHeight: '90vh', overflow: 'scroll' }}>
              <Row style={{ justifyContent:'space-between',padding:'1em'}}>
                <p className="blue_text">Shipment Information</p>
                <ButtonGroup  spacing="2">
                  <Button colorScheme='purple' size='sm'  onClick={() =>this.setState({offersModalShow:true})} >Offers</Button>
                  <Button colorScheme='orange' size='sm' onClick={()=>this.setState({assignCarrierModalShow:true})} >Assign Carrier</Button>
                  <Button colorScheme='green' size='sm' onClick={() => window.location=`/editshipment/${shipment._id}` } >Edit</Button>
                  <Button colorScheme='red' size='sm' onDoubleClick={this.handleDelete}>Delete</Button>
                </ButtonGroup>
              </Row>
              <hr />
               {shipment!=null ? <ShipmentInfoCard key={shipment._id} shipment={shipment} />:null}
            </div>
        </div>
		
		<div className="col-md-6 col-sm-12 mb-5 mt-2 " style={{ maxHeight: '90vh' ,float:'left',padding:0}} >
      <button
      style={{ position:'absolute',right:10,zIndex:1000}}
      onClick={()=>this.setState({show_map:!this.state.show_map})} className='btn btn-primary mb-2'>Toggle Map</button>
		 <div className="container shadow-sm" style={{ background: "#ffffff", maxHeight: '90vh',zIndex:-2,padding:0 }}>
		  {this.state.show_map &&
		  <div className="map_img" style={{height:'90vh'}}  id="maps" > 
      <Map center={this.state.map_center} zoom={10} style={{zIndex:0,padding:0,height:'90vh'}} >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" 	
        />
        <MyMarkersList markers={this.state.markers} shipment_id={shipment._id} />
      </Map>
      </div> }
    
		 </div>
		</div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.shipments_reducer,
    carriers_data: state.carriers_reducer,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    get_carriers: () => dispatch(get_carriers_async()),
    get_shipment_by_id: (id, callback) => dispatch(get_shipment_by_id_async(id, callback)),
    get_offers: (id, callback) => dispatch(get_offers_async(id, callback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentInfo));