import React, { useEffect,useState } from "react";
import CardTitle from "./CardTitle";
import Card from "../Common/Card";
import PlusButton from "../Common/PlusButton";
import NavBar from "../Common/NavBar";
import ShipmentsFilter from "../Common/ShipmentsFilter";
import { useQuery,useQueryClient } from "react-query";
import { Skeleton, Stack, SkeletonText, Button } from "@chakra-ui/react"
import {  URL } from "../globals";
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import Row from "../Common/Layout/Row";

const buttontext = [
  { id: 1, shipment_state: "In progress" },
  { id: 2, shipment_state: "Upcoming" },
  { id: 3, shipment_state: "Finished" },
  { id: 4, shipment_state: "Waiting" }
];
const filter_status=['WAITING','UPCOMING','INPROGRESS']

const getData = async (getUrl)=>{
  const {data} = await axios.get(`${URL}${getUrl}`)
  return data
}

export default function Shipments() {
  const queryClient = useQueryClient()
  const [filtered, setfiltered] = useState(false)
  const [filteredShipments, setfilteredShipments] = useState([])
  const history = useHistory()

  const {data, isLoading} = useQuery('shipments',()=>getData(`crud/shipments`),{
    initialData: () => {
     console.log(queryClient.getQueryData('shipments'))
     return queryClient.getQueryData('shipments')
      }
  })
  
  

  return (
    <div>
    <NavBar/>
        <div className="container-fluid pt-5">
          <div className="row">
            <div className="col-md-9 offset-md-1">
              <Row style={{ justifyContent:'space-between'}}>
              <span className="main_title">Shipments</span>
              <Button 
              colorScheme='green'
              onClick={()=>history.push("newshipment")}
              >+ Shipment</Button>
              </Row>
              <CardTitle />

              <div
                style={{
                  overflow: "scroll",
                  maxHeight: "80vh",
                  msOverflowStyle: "none",
                  paddingBottom:40
                }}
                className='hiddenoverflow'
              >
  
               
                {isLoading ?
                <Stack marginTop='2em'>
                <Skeleton height="10vh" />
                <Skeleton height="10vh" />
                <Skeleton height="10vh" />
                <Skeleton height="10vh" />
                <Skeleton height="10vh" />
                <Skeleton height="10vh" />
              </Stack> 
              :
                filtered?
                filteredShipments.map(item => {
                    return <Card key={item.id} item={item} />;
                  }):
                  data.data.map(item => {
                    return <Card to='shipmentinfo' key={item.id} item={item} />;
                })
                
                }
             
              </div>
            </div>
              <ShipmentsFilter setShipments={setfilteredShipments} setfiltered={setfiltered} shipments={data ? data.data:[]} type='admin' />
          </div>
        </div>
      </div>
  )
}


