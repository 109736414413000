import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './Styles/style.css'
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import rootreducer from "./Redux/reducers/root_reducer";
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { ChakraProvider } from "@chakra-ui/react"
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { createBreakpoints } from "@chakra-ui/theme-tools"
export const store = createStore(
  rootreducer,
  composeWithDevTools(applyMiddleware(thunk))
);

// This is the default breakpoint
const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
})

const queryClient = new QueryClient()
ReactDOM.render(
  <Provider store={store}>
   <QueryClientProvider client={queryClient}>
  <BrowserRouter>
    <ReactNotification />
    <ChakraProvider>
    <App />
    </ChakraProvider>
  </BrowserRouter>
  </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);


serviceWorker.unregister();
