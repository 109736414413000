import React from "react";
import Joi from "joi-browser";
import InputField from "../Components/Common/InputField";
import logo from "../Pics/logo_with_text.svg";
import Form from "../Components/Common/Form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AdminLogin } from "../Redux/actions/admin_actions_creators";
import axios from "axios";
import { URL } from "../globals";

import {
  createLocalStorageAdmin,
  getAdmin,
} from "../LocalStorage/Auth";
import "../Styles/LogInForm.css";
class LogInForm extends Form {
  state = {
    data: { email: "", password: "" },
    error: {},
    link: "",
    box_clicked_customer: false,
    box_clicked_carrier: false,
    box_error: false,
    invalid_login: false,
    loading: false,
    loginButtonSpinner: false,
  };

  // schema for valiation
  schema = {
    email: Joi.string().email({ minDomainAtoms: 2 }).required().label("Email"),
    password: Joi.string().required().label("Password"),
  };
  /*
  componentDidMount()
  {
	  const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
     const	requestbody = {
		admin_name: "admin",
		admin_email: "admin@gmail.com",
		admin_password: "admin@1234",
	}
	
	
  axios
    .post(`${URL}api/admin/createadmin`, requestbody, config)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error.response);
   
    });
  }
  */

  componentDidMount(){
  }

  spinnerButton = () => {
    this.setState({ loginButtonSpinner: true });
  };
  //Submit the form
  adminLogin = (e) => {
    e.preventDefault();
    this.spinnerButton();
    // either user select one of the box or not
	console.log("aman1");
    
    // validation of input fields
    const error = this.validate();
    this.setState({ error: error || {} });
    //if error occur don't call api
    if (error) return null;
console.log("aman3");
    //call the api
    this.callServerApi();
  };
  callServerApi = () => {
    this.setState({ invalid_login: false });
    const requestBody = {
      email: this.state.data.email,
      password: this.state.data.password,
    };
	console.log(requestBody);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    
      this.props.login(
        requestBody,
        config,
        this.LoginSucessAdmin,
        this.LoginFailureAdmin
      );
    
  };

  LoginSucessAdmin = (token, obj) => {
    createLocalStorageAdmin(token, obj);
    this.props.history.push("/Shipments");
  };
  LoginFailureAdmin = () => {
    this.setState({ invalid_login: true, loginButtonSpinner: false });
  };


  render() {
	  
    if (getAdmin()) {
      this.props.history.push("/Shipments");
      return null;
    } else
      return (
        <React.Fragment>
          <div
            className="container-fluid h-100"
            style={{ background: "#ffffff",height:'100vh' }}
          >
            <div className="row h-100">
              <div className="logo--position d-inline">
                <img src={logo} className="img-fluid" alt="logo"></img>
              </div>
              <div className="col-md-4 offset-md-1">
                <div className="top__login--margin">
                  <div className="form__padding--smallscreen">
                    <span className="fontchange d-block">
                      Sign in to WooCargo Admin
                    </span>
                    <small className="text--color">
                      Please enter your credentials to proceed.
                    </small>
                   
                   
                    {/*--------------Invalid Credentials--------  */}
                    {this.state.invalid_login && (
                      <div
                        className="alert alert-danger"
                        style={{ fontSize: "14px" }}
                      >
                        The email and password you entered did not match our
                        records. Please double-check and try again.
                      </div>
                    )}
                    {/*-----------------Form begins--------------*/}
                    <form onSubmit={this.adminLogin}>
                      <div className="form-group">
                        <InputField
                          labeltitle="EMAIL"
                          typename="email"
                          fieldname="email"
                          changeHandler={this.handleChange}
                          value={this.state.data.email}
                          error={this.state.error.email}
                        />
                      </div>
                      <div className="form-group">
                        <InputField
                          labeltitle="PASSWORD"
                          typename="password"
                          fieldname="password"
                          changeHandler={this.handleChange}
                          value={this.state.data.password}
                          error={this.state.error.password}
                        />
                      </div>
                      <button
                        disabled={
                          this.validate() || this.state.loginButtonSpinner
                        }
                        type="submit"
                        className="btn btn-primary btncolor btn-block btn-lg"
                      >
                        {this.state.loginButtonSpinner ? (
                          <span>Loading ...</span>
                        ) : (
                          <span>Sign in</span>
                        )}
                      </button>
                     
                    </form>
                    {/*-----------form End-----------  */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-1 pr-0">
                <div className="bgpic"></div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (requestBody, config, LoginSucessAdmin, LoginFailureAdmin) => {
      dispatch(
        AdminLogin(
          requestBody,
          config,
          LoginSucessAdmin,
          LoginFailureAdmin
        )
      );
    },
   
  };
};
export default connect(null, mapDispatchToProps)(LogInForm);
