import React, { Component } from "react";
import "../Styles/NavBar.css";

export default class CardTitle extends Component {
  render() {
    return (
      <div className="mt-5">
        <div className="row">
          <div className="col-2">
            <span className="gray_text">DESCRIPTION ID</span>
          </div>
          <div className="col-4">
            <span className="gray_text">PICKUP LOCATION</span>
          </div>
          <div className="col-4">
            <span className="gray_text">DELIVERY LOCATION</span>
          </div>
          <div className="col-2">
            <span className="gray_text">PRICE</span>
          </div>
        </div>
      </div>
    );
  }
}
