import { store } from 'react-notifications-component';
import io from "socket.io-client";
import { getAdminUserId } from './LocalStorage/Auth';
import {store as storee} from './index'
import axios from 'axios'

export const api_callback=(success,title,message)=>{
    if(success){
        store.addNotification({
            title: title,
            message: message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 4000,
              onScreen: true
            }
          });
    }
    else{
        store.addNotification({
            title: title,
            message: message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 4000,
              onScreen: true
            }
          });
    }
    }

    export const api_url=()=>{

      if(process.env.NODE_ENV==='development'){
        return 'http://localhost:8000/api/'
      }else if(process.env.NODE_ENV==='production'){
        return "https://woocargo.herokuapp.com/api/"
      }else{
        return  '';
      }

      }
      
export const URL = api_url();  
export const api_url2=()=>{

  if(process.env.NODE_ENV==='development'){
    return 'http://localhost:8000/'
  }else if(process.env.NODE_ENV==='production'){
    return "https://woocargo.herokuapp.com/"
  }else{
    return  '';
  }

  }
  
export const URL2 = api_url2(); 

export const first_capital=word=>{
    word=word.toLowerCase();
   return word.charAt(0).toUpperCase() + word.slice(1)
}

export const transform_shipment_attributes = (object) => {
  let my_obj = {};

  attributePriority.map(attribute=>{
    let name = attribute;
    let value = object[attribute];
    if (attribute == 'hydraulic_door') name = 'Hydraulic Doors';
    if(attribute=='dimensions') value=`${object['width']}*${object['height']}*${object['length']}`
    if (object[attribute] === true) value = 'Yes';
    if (object[attribute] === false) value = 'No'; 
    my_obj[first_capital(name)] = value;
  })
  return my_obj;
};
const attributePriority =['quantity','packing','weight','dimensions','hydraulic_door']

   export const handleNotifications =()=>{
    let userid=getAdminUserId().id

    const notificationsocket = io(`${URL2}notifications?userid=${userid}`);
      notificationsocket.on("connect", () => {
     });
     notificationsocket.on('notification',(notification)=>{
       console.log(notification)
       store.addNotification({
        title: notification.notification_title,
        message: notification.notification_content,
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      });
     })
     return notificationsocket
  }
  


 export  const getData = async (getUrl)=>{
    const {data} = await axios.get(`${URL}${getUrl}`)
    return data
  }

  
export  const deleteData = async (deleteUrl)=>{
  const {data} = await axios.delete(`${URL}${deleteUrl}`)
  return data
}

export  const postData = async (body,url)=>{
  const {data} = await axios.post(`${URL}${url}`,body)
  return data
}

export  const putData = async (body,url)=>{
  const {data} = await axios.put(`${URL}${url}`,body)
  return data
}

export  const states = [
  { value: 'all', label: 'All' },
  { value: 'waiting', label: 'Waiting' },
  { value: 'upcoming', label: 'Upcoming' },
  { value: 'inprogress', label: 'In Progress' },
  { value: 'completed', label: 'Completed' },
]

export const time_options = [
  { value: '03:00 - 06:00', label: '03:00 - 06:00' },
  { value: '06:00 - 09:00', label: '06:00 - 09:00' },
  { value: '09:00 - 12:00', label: '09:00 - 12:00' },
  { value: '12:00 - 15:00', label: '12:00 - 15:00' },
  { value: '15:00 - 18:00', label: '15:00 - 18:00' },
  { value: '18:00 - 21:00', label: '18:00 - 21:00' },
  { value: '21:00 - 24:00', label: '21:00 - 24:00' },
];

export const palets_types = [
  { value: 'Ευρωπαλέτα - 120*80*100', label: 'Ευρωπαλέτα - 120*80*100' },
  { value: 'Βιομηχανική - 100*120*100', label: 'Βιομηχανική - 100*120*100' },
  { value: 'Custom - 100*100*100', label: 'Custom - 100*100*100' },
];
export const kibvtia_types = [
  { value: '60*40*40', label: '60*40*40' },
  { value: '40*40*40', label: '40*40*40' },
  { value: '40*30*40', label: '40*30*40' },
  { value: 'Βαρέλια - 60*60*100', label: 'Βαρέλια - 60*60*100' },
 
];
export const packaging_options= [
  { value: 'Παλέτες', label: 'Παλέτες' },
  { value: 'Κιβώτια', label: 'Κιβώτια' },
  { value: 'Χωρίς Συσκευασία', label: 'Χωρίς Συσκευασία' },
 
  ]