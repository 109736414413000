import React from "react";
import "../Styles/Customer.css";
export default function InputField({
  label,
  label_text,
  name,
  changeHandler,
  value,
  className,
  type,
  error
}) {
  
  return (
    <div className={`form-group mb-4 ${className}`}>
      {label_text && <label className="gray_text bold text-uppercase">{label_text}</label>}
      {error ? 
      <input
      type={type}
      name={`${name}`}
      className={`form-control bg-error`}
      onChange={changeHandler}
      placeholder=''
      value={value}
    />
    :
    <input
      type={type}
      name={`${name}`}
      className={`form-control `}
      onChange={changeHandler}
      placeholder=''
      value={value}
    />
    }
      
      {error && <span style={{color:'red'}}>{error}</span>}
    </div>
  );
}
