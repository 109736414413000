import React from "react";
import "../Styles/NavBar.css";

const  PlusButton=(props) =>{
  return (
    <button onClick={props.onClick} className="plus_button">
    +
    </button>
  )
}

export default PlusButton;