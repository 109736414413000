import React from 'react'
import dateformat from 'dateformat'
import uparrow from '../../../Pics/up_icon.png'
import downarrow from '../../../Pics/down_icon.png'
import Row from '../../../Common/Layout/Row'

export default function Datetime({pickup_date,pickup_time,dropoff_date,dropoff_time,pickup_location,dropoff_location}) {
    return (
        <div className=" col-12 row p-3"> 
        <div className="col-6">
          <img
            src={uparrow}
            alt="icon"
            style={{ width: "2em", height: "2em" }}
            className="img-fluid d-block"
          />
          <div className='d-flex justify-content-between'>
          <span className="offer-description d-block mt-1">
            {pickup_location.address}
          </span>
          </div>
          <Row>
         <span className="gray_text mr-2">{pickup_date && dateformat(pickup_date.slice(0, 10),'d-m-yyyy')}</span> 
         <span className="gray_text">{pickup_time}</span> 
         </Row>
        </div>

          
        <div className="col-6">
          <img
             src={downarrow}
            alt="icon"
            style={{ width: "2em", height: "2em" }}
            className="img-fluid d-block"
          />
          <div className='d-flex justify-content-between'>
          <span className="offer-description d-block mt-1">
            {dropoff_location.address}
          </span>
          </div>
          <Row>
         <span className="gray_text mr-2">{dropoff_date&&dateformat(dropoff_date.slice(0, 10),'d-m-yyyy')}</span> 
         <span className="gray_text d-block">{dropoff_time}</span> 
         </Row>
        </div> 
      </div>
    )
}
