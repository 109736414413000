import React from 'react'
import Crud from '../../Common/Crud/Crud'
import NavBar from '../../Common/NavBar'
import * as yup from 'yup';
export default function Customers() {
    return (
        <div>
           <NavBar/>
           <Crud
           queryString='customers'
           getUrl="crud/customers"
           action="CUSTOMERS"
           title='Customers'
           reducer='customers_reducer'
           reduxSelector='customers'
           schema={[
            {label:"Name",dbvalue:"customer_name",show:{get:true,add:true,edit:true}},
            {label:"id",dbvalue:"_id",show:{get:false,add:false,edit:true}},
            {label:"Email",dbvalue:"customer_email",show:{get:true,add:true,edit:true}},
            {label:"Company Name",dbvalue:"company_name",show:{get:true,add:true,edit:true}},
            {label:"VAT ",dbvalue:"vat_number",show:{get:true,add:true,edit:true}},
            {label:"Tax Office",dbvalue:"tax_office",show:{get:true,add:true,edit:true}},
            {label:"Mobile Phone",dbvalue:"customer_mobilenum",show:{get:true,add:true,edit:true}},
            {label:"Password",dbvalue:"customer_password",show:{get:false,add:true,edit:false}},
                ]}
         formValues={{}}
         validationSchema = { 
            yup.object().shape({
            customer_name: yup.string().required(),
            customer_email: yup.string().email().required(),
            customer_mobilenum: yup.string().required(),
            //customer_password:yup.string().required()
          })}
           />
           
        </div>
    )
}
