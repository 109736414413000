import React,{useEffect} from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Spinner,
    Box,
    Text,
    Button,
    useToast,
    Tooltip,
  } from "@chakra-ui/react"
import {useQuery,useQueryClient,useMutation} from 'react-query'
import { getData, postData, putData } from '../../../globals'
import MySelect from '../../../Common/Forms/MySelect'
import {FormikProvider, useFormik} from 'formik'
import Row from '../../../Common/Layout/Row'

export default function AssignCarrier({isOpen,onClose,shipment_id,selected_carriers=[]}) {

  const queryClient = useQueryClient()
  const toast = useToast()
  //Get Carriers 
  const {data, isLoading,refetch} = useQuery('carriers',()=>getData('crud/carriers'),{
    initialData: () => {
     return queryClient.getQueryData('carriers')
      },
      enabled:false
  })

   //Accept Offer 
   const assignCarriers= useMutation((body)=>postData(body,`shipments/${shipment_id}/addcarriers`),{

    onSuccess: (data)=>{
     toast({
      title: "Success", 
      description: "Carriers Added",
      status: "success",
      duration: 3000,
      isClosable: true,
     })
     setTimeout(() => {
        window.location.reload() 
     }, 2000)
    }
  })

  const formik = useFormik({
    initialValues: {},
    onSubmit: body =>{
        assignCarriers.mutate(body) 
    }
  });

    return (
        <Drawer
        isOpen={isOpen}
        placement="left"
        size='lg'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent bgColor='rgba(250,250,250,1)'>
          <DrawerCloseButton />
          <DrawerHeader>Assign Carrier</DrawerHeader>
          
          <DrawerBody>
           <form onSubmit={formik.handleSubmit}>
            <MySelect 
            queryString='carriers'
            isFetching
            getUrl='crud/carriers'
            label='Select Carriers'
            label1='carrier_name'
            label2='carrier_email'
            name='carriers[0]'
            onChange={formik.setFieldValue}
            />
            <Button 
            type='submit' 
            isLoading={assignCarriers.isLoading} 
            colorScheme='blue' 
            marginTop='1em'
            >
            Assign
            </Button>
           </form>  

           <Text marginTop='1em' fontSize='lg'>Assigned Carriers</Text>
           {isLoading ?<Spinner />
           :
           data && data.data.map(carrier=> selected_carriers.includes(carrier._id) && <CarrierCard carrier={carrier}/>)
           }

          </DrawerBody>

         
        </DrawerContent>
      </Drawer>
    )
}


const CarrierCard =({carrier})=>{
    return (
    <Box backgroundColor='white' marginTop='1em' rounded='md' borderColor='rgba(0,0,0,.1)' borderWidth='1px' padding='1em' >

        <Text color='blue'>{carrier.carrier_name}</Text>
        <Row style={{flexWrap:'wrap'}}> 
            <Text color='gray.500' marginRight='1em'>{carrier.carrier_email}</Text>
            <Text color='gray.500' marginRight='1em'>{carrier.carrier_phone}</Text>
        </Row>

    </Box>
    )
}