import React,{useState,useEffect} from 'react'
import MyDatePicker from '../../Common/Forms/MyDatePicker'
import MyInput from '../../Common/Forms/MyInput'
import MySelect from '../../Common/Forms/MySelect'
import MySwitch from '../../Common/Forms/MySwitch'
import { Button, Text,Grid,GridItem ,SimpleGrid, AlertTitle, useToast} from "@chakra-ui/react"
import {useFormik} from 'formik'
import Navbar from '../../Common/NavBar'
import {useQuery,useMutation,useQueryClient} from 'react-query'
import { kibvtia_types, packaging_options, palets_types, postData, putData, time_options } from '../../globals'
import InputPlacesAutoComplete from '../../Common/Forms/InputPlacesAutoComplete'
import { useHistory } from 'react-router-dom';

export default function NewShipment({editMode=false,shipment={}}) {

    const [packingOptions, setpackingOptions] = useState(palets_types)
    const queryClient = useQueryClient()
    const history = useHistory();
    const toast= useToast();
    //Add Shipment 
    const addShipmentMutation= useMutation('adminshipments',(body)=>postData(body,'crud/shipments'),{
    onSuccess: (data)=>{
        toast({
            title: "Success", 
            description: "Shipment Added",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
      queryClient.invalidateQueries('adminshipments')
      history.push('Shipments')
    }
  })

     //Edit Shipment 
     const editShipmentMutation= useMutation(['shipmentinfo',shipment?._id],(body)=>putData(body,'crud/shipments'),{
        onSuccess: (data)=>{
            toast({
                title: "Success", 
                description: "Shipment Edited",
                status: "success",
                duration: 3000,
                isClosable: true,
              })
          history.push(`/shipmentinfo/${shipment?._id}`)
        }
      })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{...shipment},
        onSubmit: (body) =>{            

            editMode ?editShipmentMutation.mutate(body): addShipmentMutation.mutate(body)
        }
    })

    const handleFieldValue =(name,value)=>{
        if(name=='shipment_attributes.packing'){
           if(value=='Παλέτες'){
            setpackingOptions(palets_types)
            } else if(value=='Κιβώτια'){
            setpackingOptions(kibvtia_types)
           }else{
            setpackingOptions([])
           }
        }
        if(name=='shipment_attributes.dimensions'){
        const  {length,width,height} =splitDimensions(value)
        formik.setFieldValue('shipment_attributes.length',length)
        formik.setFieldValue('shipment_attributes.width',width)
        formik.setFieldValue('shipment_attributes.height',height)
            
        }
        formik.setFieldValue(name,value)
    }
    const handleChange =(e)=>formik.setFieldValue(e.target.name,e.target.value)

    const splitDimensions =(dim)=>{
        let dim_trimmed = dim.toString().trim().split(/\s/).join('');
          let word_split = dim_trimmed.substring(dim_trimmed.indexOf('-') + 1);
          let dimensions = word_split.split('*');
          let length = dimensions[0];
          let width = dimensions[1];
          let height = dimensions[2];
          return { length,width,height}
    }
   
    return (
        <>
        <Navbar />
        <div className='container bg-white mt-5 pt-3 pb-4 ' style={{overflow:'scroll'}}>   
            <Text fontSize="2xl" color='blue' marginBottom={10}>{editMode?"Edit Shipment" :"New Shipment"}</Text>
            {(editMode && Object.keys(shipment)?.length>0) ||(!editMode ) ?
                <form  onSubmit={formik.handleSubmit}>
                    <Grid templateColumns={["repeat(1, 1fr)","repeat(2, 1fr)","repeat(4, 1fr)"]} gap={6}>
                        

                        <GridItem colSpan={[1,2,4]}>
                        <Text fontSize="md">Location & Time</Text>
                        </GridItem>
                        <InputPlacesAutoComplete
                        label='Pickup Location'
                        onChange={handleFieldValue}
                        defaultValue={shipment?.pickup_location?.address}
                        name='pickup_location'
                        />

                        <MySwitch 
                        label='Flexible Time'
                        onChange={handleChange} 
                        name='pickup_flexible'
                        defaultChecked={shipment.pickup_date? false :true}
                         />

                        <MyDatePicker
                        name='pickup_date'
                        label='Pickup Date'
                        onChange={handleFieldValue}
                        selectedDate={shipment.pickup_date}
                        
                        />

                        <MySelect 
                        name='pickup_time'
                        label='Pickup Time' 
                        options={time_options} 
                        defaultValue={shipment.pickup_time}
                        onChange={handleFieldValue}
                        />
                        
                        <InputPlacesAutoComplete
                        label='Dropoff Location'
                        defaultValue={shipment?.dropoff_location?.address}
                        onChange={handleFieldValue}
                        name='dropoff_location'
                        />

                        <MySwitch 
                        label='Flexible Time' 
                        onChange={handleChange} 
                        name='dropoff_flexible'
                        defaultChecked={shipment.dropoff_date? false :true} />

                        <MyDatePicker 
                        name='dropoff_date'
                        label='Dropoff Date' 
                        onChange={handleFieldValue}
                        selectedDate={shipment.dropoff_date}
                        />

                        <MySelect 
                        label='Dropoff Time'
                        name='dropoff_time'
                        options={time_options} 
                        defaultValue={shipment.dropoff_time}
                        onChange={handleFieldValue}
                        />
                         <MyInput 
                        name='shipment_description'
                        label ='Description'
                        value={formik.values.shipment_description}
                        defaultValue={formik.initialValues.shipment_description}
                        onChange={handleChange} 
                         />

                        <GridItem colSpan={[1,2,4]}>
                        <Text fontSize="md">Dimensions & Weight</Text>
                        </GridItem>
                        <MySelect 
                        name='shipment_attributes.packing'
                        label='Packaging'
                        options={packaging_options}
                        defaultValue={shipment.shipment_attributes?.packing}
                        onChange={handleFieldValue}
                        />
                        <MySelect
                         name='shipment_attributes.dimensions'
                         defaultValue={shipment?.shipment_attributes?.dimensions}
                         onChange={handleFieldValue}
                         label='Dimensions'
                         options={packingOptions}
                         onChange={handleFieldValue}
                          />

                        <MyInput 
                        name='shipment_attributes.quantity'
                        label ='Quantity'
                        value={formik.values.shipment_attributes?.quantity}
                        defaultValue={formik.initialValues.quantity}
                        onChange={handleChange} 
                        type='number'
                        
                         />
                        <MyInput 
                         name='shipment_attributes.length'
                        label ='Length'
                        value={formik.values.shipment_attributes?.length}
                        defaultValue={formik.initialValues.shipment_attributes?.length}
                        type='number'
                        onChange={handleChange} 
                         />
                        <MyInput 
                          name='shipment_attributes.width'
                        label ='Width'
                        type='number'
                        value={formik.values.shipment_attributes?.width}
                        defaultValue={formik.initialValues.shipment_attributes?.width}
                        onChange={handleChange} 
                         />
                        <MyInput 
                        name='shipment_attributes.height'
                        label ='Height'
                        type='number'
                        value={formik.values.shipment_attributes?.height}
                        defaultValue={formik.initialValues.shipment_attributes?.height}
                        onChange={handleChange} 
                         />
                        <MyInput 
                        name='shipment_attributes.weight'
                        label ='Total Weight'
                        value={formik.values.shipment_attributes?.weight}
                        defaultValue={formik.initialValues.shipment_attributes?.weight}
                        onChange={handleChange} 
                         />
                         <MySwitch 
                        name='shipment_attributes.hydraulic_door'
                        label='Hydraulic Door' 
                        onChange={formik.handleChange}
                        defaultChecked={shipment?.shipment_attributes?.hydraulic_door} />
                        <MySwitch 
                        label='Add Photos' 
                        name='addphotos'
                        onChange={formik.handleChange}
                        defaultChecked={false} 
                        />
                        

                        <GridItem colSpan={[1,2,4]}>
                            <Text fontSize="md">Customer Information </Text>
                        </GridItem> 
                        <MySelect 
                        isFetching
                        queryString='customers'
                        getUrl='crud/customers'
                        label='Customer'
                        label1='customer_name'
                        label2='customer_email'
                        name='customer_id'
                        defaultValue={shipment.customer_id}
                        onChange={handleFieldValue}
                        />

                        <GridItem colSpan={[1,2,4]}>
                            <Text fontSize="md">Shipper/Receiver</Text>
                        </GridItem> 
                        <MySwitch 
                        label='Add Shipper Receiver' 
                        name='addshipperreceiver'
                        onChange={formik.handleChange}
                        defaultChecked={false} 
                        />

                        {formik.values.addshipperreceiver  &&
                        <>
                        <GridItem colSpan={[1,2,4]}>
                            <Text fontSize="md">Shipper</Text>
                        </GridItem> 
                        <MyInput 
                        name='shipper.name'
                        label ='Name'
                        value={formik.values.shipper?.name}
                        defaultValue={formik.initialValues.shipper?.name}
                        onChange={handleChange} 
                         />
                         <MyInput 
                        name='shipper.email'
                        label ='Email'
                        value={formik.values.shipper?.email}
                        defaultValue={formik.initialValues.shipper?.email}
                        onChange={handleChange} 
                         />
                        <MyInput 
                        name='shipper.mobile_number'
                        label ='Mobile Number'
                        value={formik.values.shipper?.mobile_number}
                        defaultValue={formik.initialValues.shipper?.mobile_number}
                        onChange={handleChange} 
                         />
                           <GridItem colSpan={[1,2,4]}>
                            <Text fontSize="md">Receiver</Text>
                        </GridItem> 
                        <MyInput 
                        name='receiver.name'
                        label ='Name'
                        value={formik.values.receiver?.name}
                        defaultValue={formik.initialValues.receiver?.name}
                        onChange={handleChange} 
                         />
                         <MyInput 
                        name='receiver.email'
                        label ='Email'
                        value={formik.values.receiver?.email}
                        defaultValue={formik.initialValues.receiver?.email}
                        onChange={handleChange} 
                         />
                        <MyInput 
                        name='receiver.mobile_number'
                        label ='Mobile Number'
                        value={formik.values.receiver?.mobile_number}
                        defaultValue={formik.initialValues.receiver?.mobile_number}
                        onChange={handleChange} 
                         />


                        </>
                        }

                        <GridItem colSpan={[1,2,4]}>
                            <Button isLoading={editMode? editShipmentMutation.isLoading :addShipmentMutation.isLoading} type='submit' colorScheme='blue'>Submit</Button>
                        </GridItem>
                    </Grid>


                </form>
        :null}
        </div>
        </>
    )
}

const shipmentAttributes= ['length','height','width','weight','hydraulic_door','dimensions','packing','quantity']