import {GET_CARRIERS,FAIL_REQUEST,START_REQUEST, ADD_CARRIER,ADD_CARRIER_SUCCESS, ADD_CARRIER_START, ADD_CARRIER_FAIL, DELETE_CARRIER_SUCCESS, DELETE_CARRIER_START, DELETE_CARRIER_FAIL, EDIT_CARRIER_SUCCESS, EDIT_CARRIER_FAIL, EDIT_CARRIER_START,    GET_CARRIERS_PAYMENT,
    GET_SELECTED_CARRIERS_PAYMENT,GENERATE_INVOICE, FILTER_CARRIERS} from '../constants'

export const get_carriers_action =(carriers,count)=>{
return {
    type: GET_CARRIERS,
    payload:carriers,
    count
}
}
export const add_carrier_action =(carrier)=>{
    return {
        type: ADD_CARRIER,
        payload:carrier,
    }
    }

export const start_request=()=>{
    return {
        type: START_REQUEST,
    }
}
export const fail_request=()=>({type:FAIL_REQUEST})
 
//ADD CARRIER
export const add_carrier_success =(carrier)=>({type: ADD_CARRIER_SUCCESS,payload:carrier})
export const add_carrier_start=()=>({type:ADD_CARRIER_START})
export const add_carrier_fail=(error_message)=>({type:ADD_CARRIER_FAIL,error_message})

export const delete_carrier_success =(id)=>({type: DELETE_CARRIER_SUCCESS,payload:id})
export const delete_carrier_start=()=>({type:DELETE_CARRIER_START})
export const delete_carrier_fail=(error_message)=>({type:DELETE_CARRIER_FAIL,error_message})

export const edit_carrier_success =(id)=>({type: EDIT_CARRIER_SUCCESS,payload:id})
export const edit_carrier_start=()=>({type:EDIT_CARRIER_START})
export const edit_carrier_fail=(error_message)=>({type:EDIT_CARRIER_FAIL,error_message})


export const filter_carriers=(filtered_carriers)=>({type:FILTER_CARRIERS,filtered_carriers})


//stripe
export const get_carriers_payment_action = (carriers_payments, count) => {
    return {
        type: GET_CARRIERS_PAYMENT,
        payload: carriers_payments,
        count
    }
}

export const get_selected_carriers_payment_action = (carriers_payments, count) => {
    return {
        type: GET_SELECTED_CARRIERS_PAYMENT,
        payload: carriers_payments,
        count
    }
}

export const generate_invoice_action = (invoice_data) => {
    return {
        type: GENERATE_INVOICE,
        payload: invoice_data
    }
}