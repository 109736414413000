import React from 'react'
import Column from '../../../Common/Layout/Column'

export default function Price({price}) {
    
    return (
        <Column>
         <h2 className="blue_text" style={{fontWeight:600,fontSize:'2em'}}>{price ? price : '---'} €</h2>
         <p className="gray_text">χωρίς ΦΠΑ</p>
        </Column>
    )
}   
