import React from "react";
import "./Styles/react_dates_overrides.css"
import "./Styles/bootstrap.css";
import "./Styles/bootstrap-grid.min.css";
import "./Styles/react_dates_overrides.css"
import "./Styles/style.css";
import { Route, Switch, Redirect } from "react-router-dom";
import NavBar from "./Common/NavBar";
import Shipments from "./Shipment/Shipments";

import Carriers from "./Carriers/Carriers";
import ShipmentInfo from "./Pages/ShipmentInfo";
import NewShipment from "./Components/NewShipment/NewShipment";
import EditShipment from "./Components/ShipmentInformation/EditShipment/EditShipment";
import Login from "./Pages/Login";
import ProtectedRoute from "./Common/ProtectedRoute";
//stripe
import CarriersPayment from "./Pages/CarriersPayment";
import Crud from './Common/Crud/Crud'
import CarriersCrud from './Pages/Crud/Carriers'
import Offers from './Pages/Crud/Offers'
import Customers from "./Pages/Crud/Customers";

const RenderComp = [
  { id: 1, link: "/Shipments", Comp: Shipments },
  { id: 2, link: "/Carrierss", Comp: Carriers  },
  { id: 3, link: "/crud", Comp: Crud  },
  { id: 21, link: "/carriers", Comp: CarriersCrud  },
  { id: 10, link: "/offers", Comp:Offers },

  
           
  { id: 3, link: "/customers", Comp: Customers },
  { id: 4, link: "/shipmentinfo/:id", Comp: ShipmentInfo },
  { id: 5, link: "/NewShipment", Comp: NewShipment },
  { id: 6, link: "/editshipment/:id", Comp: EditShipment },
  { id: 7, link: "/Payment", Comp: CarriersPayment },
 
];
function App() {
  return (
    <React.Fragment>
	  <Switch>
	  
	     <Route key="7" path="/Login" component={Login} />
        {RenderComp.map(item => {
          return (
			<ProtectedRoute  path={`${item.link}`} component={item.Comp} />
          );
        })}
        <Redirect from="/" to="/Login" />
      </Switch>
    </React.Fragment>
  );
}

export default App;
