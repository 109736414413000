import React,{useState,useEffect} from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,Button,Input,FormControl,FormLabel,Field,FormErrorMessage
  } from "@chakra-ui/react"
import { Formik,useFormik } from 'formik';
import MyInput from '../../Forms/MyInput';
import {useMutation,useQueryClient} from 'react-query'
import useApiRequest from '../../../Services/useApiRequest';
import MySelect from '../../Forms/MySelect';
import MyDatePicker from '../../Forms/MyDatePicker';
import MySwitch from '../../Forms/MySwitch';
import { postData } from '../../../globals';

function AddRecord({schema,formValues,record,title,getUrl,action,validationSchema,queryString,postUrl}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {loading,data,sendRequest:createRecord} = useApiRequest({ url:getUrl, type:'post',action:`ADD_${action?.slice(0,-1)}`});

  const btnRef = React.useRef()
  const queryClient = useQueryClient()
 
  //Add Record
  const addRecordMutation = useMutation(queryString,(body)=>postData(body,postUrl),{
    onSuccess: (data)=>{
    queryClient.invalidateQueries(queryString)
    onClose()
    }
  })
  
  const formik = useFormik({
    initialValues: {...formValues},
    validationSchema, 
    onSubmit: body =>addRecordMutation.mutate(body)
  });

  return (
    <>
      <Button ref={btnRef}  size='lg' colorScheme="green" onClick={onOpen}>Add</Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size='lg'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create {title?.slice(0,-1)}</DrawerHeader>
            <DrawerBody>
            <form onSubmit={formik.handleSubmit}>
            {schema.map(s=>{
                  if(s.show.add){
                      switch (s.type) {
                          case "select":
                             return <MySelect isFetching={true} getUrl={s.getUrl} label1={s.selectLabel1} label2={s.selectLabel2} label={s.label} onChange={formik.setFieldValue} name={s.dbvalue} /> 
                        case "date":
                           return <MyDatePicker label={s.label} onChange={formik.setFieldValue} id={s.dbvalue} name={s.dbvalue} error={formik.errors[s.dbvalue]}/>
                        case "boolean":
                          return <MySwitch label='Approved' defaultChecked={formik.initialValues[s.dbvalue]} onChange={formik.handleChange} name={s.dbvalue} />
                          default:
                            return <MyInput label={s.label} value={formik.values[s.dbvalue]}  onChange={formik.handleChange} id={s.dbvalue} name={s.dbvalue} error={formik.errors[s.dbvalue]}/>
                              break;
                      }
                  }
              })}
             
      
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button isLoading={addRecordMutation.isLoading} colorScheme="blue" type='submit'>Create</Button>
            </DrawerFooter>
          </form>  
            </DrawerBody>


             
            
           
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
    )
}

export default AddRecord

