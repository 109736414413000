import {GET_CUSTOMERS,FAIL_REQUEST,START_REQUEST,
     ADD_CUSTOMER_SUCCESS, ADD_CUSTOMER_START, ADD_CUSTOMER_FAIL, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_START, DELETE_CUSTOMER_FAIL, EDIT_CUSTOMER_SUCCESS, EDIT_CUSTOMER_FAIL, EDIT_CUSTOMER_START, FILTER_CUSTOMERS} from '../constants'

export const get_customers_action =(customers,count)=>{
return {
    type: GET_CUSTOMERS,
    payload:customers,
    count
}
}
export const start_request=()=>{
    
    return {
        type: START_REQUEST,
    }
}
export const fail_request=()=>{
    return {
        type: FAIL_REQUEST,
    }
}

//ADD CUSTOMER
export const add_customer_success =(customer)=>({type: ADD_CUSTOMER_SUCCESS,payload:customer})
export const add_customer_start=()=>({type:ADD_CUSTOMER_START})
export const add_customer_fail=(error_message)=>({type:ADD_CUSTOMER_FAIL,error_message})

export const delete_customer_success =(id)=>({type: DELETE_CUSTOMER_SUCCESS,payload:id})
export const delete_customer_start=()=>({type:DELETE_CUSTOMER_START})
export const delete_customer_fail=(error_message)=>({type:DELETE_CUSTOMER_FAIL,error_message})

export const edit_customer_success =(id)=>({type: EDIT_CUSTOMER_SUCCESS,payload:id})
export const edit_customer_start=()=>({type:EDIT_CUSTOMER_START})
export const edit_customer_fail=(error_message)=>({type:EDIT_CUSTOMER_FAIL,error_message})

export const filter_customers=(filtered_customers)=>({type:FILTER_CUSTOMERS,filtered_customers})