const initialstate = {
  CustomerShipments: [],
  offers: [],
};

const customerShipmentReducer = (state = initialstate, action) => {
  if (action.type === "ADD_NEW_SHIPMENT_CUSTOMER") {
    console.log(action.data);
    return {
      ...state,
      CustomerShipments: [
        action.data.data.newshipment,
        ...state.CustomerShipments,
      ],
    };
  }
  

  return state;
};

export default customerShipmentReducer;
