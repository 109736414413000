import React, { Component } from 'react'
import MultiSelect from "react-multi-select-component"
import ReactModal from 'react-modal';
import {connect} from 'react-redux'
import { add_carriers_to_shipment } from "../Redux/actions/shipment_actions_creators";
import { api_callback } from '../globals';

 class NoSplitModal extends Component {
   constructor(props) {
      super(props);
      this.state = {
        carriers_selected:[],
        
      };
    }
    setSelected = e => {
      this.setState({carriers_selected:e});
    };
    take_id_from_selected_carrier=(arr)=>{
      let carriers_id= []
      arr.map(filter=>{
        carriers_id.push(filter.value)
      })
      return carriers_id;
    }
    
    add_carriers_to_shipment_request=()=>{
      this.props.add_carriers_to_shipment(this.props.shipment_id,{carriers:this.take_id_from_selected_carrier(this.state.carriers_selected)},this.add_carriers_to_shipment_request_callback);
    }
    add_carriers_to_shipment_request_callback=(success)=>{
      if(success){
        this.props.close(1);  
        api_callback(true,'Success','Carriers Added')
        window.location.reload()
          
      }else{
        api_callback(false,'Error','Error Occured')
      }
    }
array_to_object = (arr) => {
    var obj = []
    arr.map(data => {
      obj.push({ label: data.carrier_email, value: data._id })
    })
    return obj;
  }
   render() {
      return (
         <ReactModal
        id='no_split_modal'
        isOpen={this.props.isOpen}
        onRequestClose={()=>this.props.close(1)}
        shouldCloseOnEsc={true}
        className='split_modal'
        overlayClassName='split_modal_overlay'
        shouldCloseOnOverlayClick={true}
      >
        <p className='mt-3 ml-2 gray_text bold '>NO SPLIT MODAL</p>
        <button  style={{position:'fixed',top:10,right:10,background:'transparent',border:0}}
         onClick={()=>this.props.close(1)}>
        <img style={{height:'2em',width:'2em'}}src={require("../Pics/close.png")}></img>
        </button>
        <div className='container row'>
          <p className='black_text bold ml-3'>Select Carriers for offers</p>
            <MultiSelect
            options={this.array_to_object(this.props.carriers)}
            value={this.state.carriers_selected}
            onChange={this.setSelected}
            labelledBy={"Select"}
            className='col-12'
             />
              <hr></hr>
             <div className='col-12 mt-4'>
             
             <p className='black_text bold '>Selected Carriers for offers</p>
            {this.props.preselect_carriers!=null? this.props.preselect_carriers.map(id=>{
              let r;
             this.props.carriers.map(carrier=>{
               if(carrier._id==id){
                r= <p className='gray_text'>{carrier.carrier_name} - {carrier.carrier_email}</p>
               }
             }) 
             return r;
            }):null}
          </div>
             <button onClick={this.add_carriers_to_shipment_request} className='btn btn-primary  offset-9 col-3'>Assign</button>
        </div>

      </ReactModal> 
      )
   }
}
const mapStateToProps=(state)=>{
   return{
     data:state.shipments_reducer,
     carriers_data:state.carriers_reducer,
 
   }
 }

 const mapDispatchToProps = dispatch => {
  return {
    add_carriers_to_shipment:(id,carriers_selected,callback) => dispatch(add_carriers_to_shipment(id,carriers_selected,callback))
  }
}
 export default connect(mapStateToProps,mapDispatchToProps)(NoSplitModal);