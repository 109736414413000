import React from 'react'

export default function ShipmentPhotos({pics}) {
    return (
        <React.Fragment>
        <p className='blue_text'>Shipment Photos</p>
        {pics ? <img style={{maxWidth:'40%',maxHeight:80,borderRadius:5}} src={pics} />: <p className='gray_text'>No Photos</p>}
        </React.Fragment>
    )
}
