import { GET_NOTIFICATIONS_FAIL, GET_NOTIFICATIONS_START, GET_NOTIFICATIONS_SUCCESS } from "../constants";

const initialstate = {
    notifications: [],
    loading:false
  };
  
  const notification_reducer= (state = initialstate, action) => {
    //GET NOTIFICATIONS
    if (action.type=== GET_NOTIFICATIONS_START){
        return { ...state,loading:true}
     }
    if (action.type=== GET_NOTIFICATIONS_FAIL){
        return { ...state,loading:false,error_message:action.error_message}
    }
    if (action.type=== GET_NOTIFICATIONS_SUCCESS){
        return {...state,notifications:action.payload,loading: false}
    }
    return state;
    }
  
  export default notification_reducer;
  