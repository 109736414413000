import React, { useState,useEffect } from 'react';
import GooglePlacesAutocomplete,{geocodeByAddress} from 'react-google-places-autocomplete';

export default function InputPlacesAutoComplete({label,name,onChange,defaultValue}) {
    const [value, setValue] = useState(null);

    useEffect(() => {
       console.log(value) 
       if(value){
       geocodeByAddress(value.label)
       .then((results) => {
        console.log(results)
        const locationObject ={
            type:'Point',
            coordinates:[results[0].geometry.location.lat(),results[0].geometry.location.lng()],
            address:results[0].formatted_address
        }
        onChange(name,locationObject)
      })
      .catch((error) => console.error("Error", error));
    }
    }, [value])

  return (
    <div>
        <label className='gray_text'>{label}</label>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyClzjPWU5R8kAfeFZ43fb1MKCKZgQOeguQ"
        selectProps={{
          value,
          defaultInputValue:defaultValue,
          onChange: setValue,
          placeholder:'Type an Address'
        }}
        
      />
    </div>
  );
}
