import React,{useEffect,useState} from "react";
import "../Styles/NavBar.css";
import {  removeToken } from "../LocalStorage/Auth";
import { NavLink } from "react-router-dom";
import logo from '../Pics/logo_with_text.svg'
import { handleNotifications } from "../globals";
import { IconButton, useMediaQuery } from "@chakra-ui/react";
import {  HamburgerIcon} from '@chakra-ui/icons'
import Row from "./Layout/Row";
import Notifications from "../Components/Notifications/Notifications";
const Tabs = [
  { id: 1, text: "Shipments" },
  { id: 2, text: "Customers" },
  { id: 3, text: "Carriers" },
  { id: 4, text: "Offers" },
];


export default function NavBar() {
  const [show_notifications, setshow_notifications] = useState(false)
  const [showNav, setshowNav] = useState(true)
  const [lessThan700px] = useMediaQuery("(max-width: 700px)")
  const [notificationSocket, setnotificationSocket] = useState(null)

  useEffect(() => {
    if(!notificationSocket){
     setnotificationSocket(handleNotifications())
    }
  }, [notificationSocket])
  
  const signOutCustomer = () => {
    removeToken();
  };
  useEffect(() => {
   if(!lessThan700px && !showNav){
     setshowNav(true)
   } 
   if(lessThan700px&&showNav){
     setshowNav(false)
   }
  }, [lessThan700px])

  return (
    <div id='navbar'  className="d-flex justify-content-md-around justify-content-between bg_white">
      {/* Logo */}
        <img id='logo' src={logo} alt="" style={{width:'9em'}} />
      {/* Tabs */}
      <ul style={{ display:`${showNav?"flex":"none"}`}}>
        {Tabs.map(item => {
          return (
            <li>
            <NavLink
              to={`/${item.text}`}
              key={item.id}
              className='black_text'
              activeClassName="active"
            >
              {item.text}
            </NavLink>
            </li>
          );
        })}
		     <li className="black_text bold" style={{ cursor: "pointer" }}onClick={signOutCustomer}>SignOut</li>
        </ul>
          
        <Row style={{ paddingRight:'1em',alignItems:'center'}}>
         <Notifications/>
        <div id='nav_close_button'>
        <IconButton
        style={{zIndex:101}}
        colorScheme="transparent"
        onClick={()=>setshowNav(!showNav)}
        aria-label="Call Segun"
        size="md"
      icon={<HamburgerIcon color='black' />}
      />
        </div>
       
        </Row>
      </div>
  )
}
