import React, { Component } from 'react'
import MultiSelect from "react-multi-select-component"
import ReactModal from 'react-modal';
import {connect} from 'react-redux'
import ShipmentInfo from '../Pages/ShipmentInfo';
import OfferCard from '../Common/OfferCard'
 class CustomerChatModal extends Component {
   constructor(props) {
      super(props);
      this.state = {
        carriers_selected:[],
      };
    }
   
   render() {
     const shipment = this.props.data;
     console.log(shipment)
      return (
        <ReactModal
        id='no_split_modal'
        isOpen={this.props.isOpen}
        onRequestClose={()=>this.props.close(2)}
        shouldCloseOnEsc={true}
        className='split_modal'
        overlayClassName='split_modal_overlay'
        shouldCloseOnOverlayClick={true}
      >
        <p className='mt-3 ml-2 gray_text bold '>Offers</p>
        
        <button  style={{position:'fixed',top:10,right:10,background:'transparent',border:0}}
        onClick={()=>this.props.close(2)}>
        <img style={{height:'2em',width:'2em'}}src={require("../Pics/close.png")}></img>
        </button>
        <hr></hr>
      {shipment!=null ?  shipment.offers!=undefined && shipment.offers!=null?
        shipment.offers.map(offer=>{
          return <OfferCard offer={offer} shipment_id={this.props.shipment_id}></OfferCard>
        }) 
       :null:null}

      </ReactModal>
      )
   }
}


 
 export default connect(null,null)(CustomerChatModal);