import React from 'react'
import {first_capital} from '../../globals'

export default function ShipperReceiver({data,type}) {
    return (
        <div className="d-flex flex-column ">
        <p className="offer-description">{first_capital(type)}</p>
        <p  className="offer-notes ml-2">{data.name}</p>
        <p  className="offer-notes ml-2">{data.email}</p>
        <p  className="offer-notes ml-2">{data.mobile_number}</p>
        <p  className="offer-notes ml-2">{data.address}</p>
        </div>
    )
}
