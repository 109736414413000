import axios from "axios";
import { URL } from "../globals";
import { LoginAction } from "../Redux/actions/admin_actions";

const loginService = (
  requestbody,
  config,
  dispatch,
  LoginSucessAdmin,
  LoginFailureAdmin
) => {
  axios
    .post(`${URL}admin/login`, requestbody, config)
    .then(function (response) {
      dispatch(LoginAction(response.data));
      //callback function to change routes or etc
      LoginSucessAdmin(response.data.authToken, response.data.customer);
    })
    .catch(function (error) {
      LoginFailureAdmin();
      console.log(error);
    });
};

export default loginService;
