import { GET_CARRIERS, GET_CARRIERS_PAYMENT,
  GET_SELECTED_CARRIERS_PAYMENT, GENERATE_INVOICE, START_REQUEST, ADD_CARRIER , ADD_CARRIER_SUCCESS, ADD_CARRIER_START, ADD_CARRIER_FAIL, DELETE_CARRIER_START, DELETE_CARRIER_FAIL, DELETE_CARRIER_SUCCESS, EDIT_CARRIER_START, EDIT_CARRIER_FAIL, EDIT_CARRIER_SUCCESS, FILTER_CARRIERS } from "../constants";
const initialstate = {
  carriers: [],
  filtered_carriers:[],
  carriers_count: null,
  filter_applied:false,
  filtered_records:[],
  loading:false,
  invoice_status: null,
  selectedCarriers: []
  //show_filtered_shipments:false,
 // show_all_shipments:true
  };
  
  const carriers_reducer = (state = initialstate, action) => {
    if (action.type === GET_CARRIERS) {
      return {
        ...state,
       carriers:action.payload,
       carriers_count:action.count,
       loading:false
      };
    }
    if (action.type === ADD_CARRIER) {
      console.log('form refucrer')
      const carriers = state.carriers.concat(action.payload)
      return {
        ...state,
       carriers 
    }
  }
  if(action.type==="GET_CARRIERS_SUCCESS"){ return {...state,carriers:action.payload.data.data,filter_applied:false}}
  if(action.type==="ADD_CARRIER_SUCCESS"){ 
    const carriers = state.carriers.concat(action.payload.data.data)
    return {...state,carriers}}
   if(action.type==="DELETE_CARRIER_SUCCESS"){ 
     const carriers =state.carriers.filter(element => element._id !== action.payload.data.id)
     return {...state,carriers}}
  if(action.type==="EDIT_CARRIER_SUCCESS"){
    const carriers =state.carriers.map(element => element._id === action.payload.data.data._id? action.payload.data.data :element) 
    return {...state,carriers}
  }
  if(action.type==='RESET_FILTERS_CARRIERS'){
    return {...state,filter_applied:false,filtered_records:[]}
  }
  if(action.type==="FILTER_CARRIERS_SUCCESS"){ return {...state,filtered_records:action.payload.data.data,filter_applied:true}}
  if (action.type === GET_CARRIERS_PAYMENT) {
    return {
      ...state,
      carriers: action.payload,
      carriers_count: action.count,
      loading: false
    };
  }
  if (action.type === GENERATE_INVOICE) {
    return {
      ...state,
      invoice_status:action.payload,
     loading:false
    };
  }
  if (action.type === GET_SELECTED_CARRIERS_PAYMENT) {
    return {
      ...state,
      selectedCarriers: action.payload,
      carriers_count: action.count,
      loading: false
    };
  }
    if (action.type === START_REQUEST) {
      return {
        ...state,
       loading:true
      };
    }
    if (action.type=== ADD_CARRIER_START) return { ...state,loading:true}
    if (action.type=== ADD_CARRIER_FAIL) return { ...state,loading:false,error_message:action.error_message}
    if (action.type=== ADD_CARRIER_SUCCESS){
        let carriers = state.carriers;
        carriers.push(action.payload)
       return { ...state,loading: false,carriers }
      }

      if (action.type=== DELETE_CARRIER_START) return { ...state,loading:true}
      if (action.type=== DELETE_CARRIER_FAIL) return { ...state,loading:false,error_message:action.error_message}
      if (action.type=== DELETE_CARRIER_SUCCESS){
          let carriers = state.carriers;
        carriers=  carriers.filter(carrier=> carrier._id!= action.payload)
         return { ...state,loading: false,carriers }
        }

        if (action.type=== EDIT_CARRIER_START) return { ...state,loading:true}
        if (action.type=== EDIT_CARRIER_FAIL) return { ...state,loading:false,error_message:action.error_message}
        if (action.type=== EDIT_CARRIER_SUCCESS){
            let carriers = state.carriers;
            console.log(action.payload)

          carriers = carriers.map( carrier =>{
           if( carrier._id===action.payload._id ){
              return action.payload
            }else{
              return carrier
            } 
          });
          console.log(carriers)

           return { ...state,loading: false,carriers }
          }
    if (action.type=== FILTER_CARRIERS) return { ...state,filtered_carriers:action.filtered_carriers,filter_applied:true}
    return state;
  };
  
  export default carriers_reducer;
  