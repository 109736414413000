import React, { Component } from "react";
import CarriersData from "../Carriers/CarriersData";
import TitleRow from "../Common/TitleRowPayment";
import { connect } from 'react-redux'
import { get_carriers_payment_async } from '../Redux/actions/carriers_actions_creators'
import { get_selected_carriers_payment_async, generate_invoice_async } from '../Redux/actions/carriers_actions_creators'
import NavBar from "../Common/NavBar";
import CardCCP from "../Common/CardCCP";
import moment from 'moment';

const Label_Info = [
  { label_text: "Carrier ID", label: true, field_name: "_id" },
  { label_text: "Carrier Email", label: true, field_name: "carrier_email" },
  { label_text: "Address", label: true, field_name: "carrier_phone" },
  { label_text: "Type of Truck", label: true, field_name: "carrier_name" }
];
const months = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

let total_cost = 0;

class carriers_payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstfield: "",
      secondfield: "",
      address: "",
      type: "",
      data: CarriersData,
      filterrecord: [],
      allshow: true,
      filtershow: false,
      add_modal_visibility: false,
      edit_modal_visibility: false,
      selected_carrier_for_edit: null,

      selectedMonth: "1",
      selectedCarrier: "",
      carrier_name: "",
      carrier_email: ""
    };
  }
  componentDidMount() {
    this.props.get_carriers_payment();
  }
  componentDidUpdate() {
    this.props.data && this.props.data.carriers && this.state.selectedCarrier === "" &&
      this.setState({
        selectedCarrier: this.props.data.carriers[0]._id
      }, () => {
        const { selectedMonth, selectedCarrier } = this.state;
        console.log('selectedCarrier', selectedCarrier, 'selectedMonth', selectedMonth);
      
        if (selectedCarrier && selectedMonth) {
          this.props.get_selected_carriers_payment(selectedCarrier, selectedMonth);
        }
      })
  }

  changeHandler = e => {
    console.log('target name', e.target.name, 'target value ', e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const { selectedMonth, selectedCarrier } = this.state;
      
      if (selectedCarrier && selectedMonth) {
        
        this.props.get_selected_carriers_payment(selectedCarrier, selectedMonth);
      }
    });
  }

  generateInvoice = e => {
    let created_at =  new Date();
    const { selectedMonth, selectedCarrier, carrier_name, carrier_email } = this.state;
    // const created_at = today.getDate();
    let expiry_date = new Date();
    expiry_date.setDate(created_at.getDate()+2);
    created_at = moment(new Date()).format('YYYY-MM-DD')
    expiry_date = moment(expiry_date).format('YYYY-MM-DD')
    const per_amount = 10*(e.target.getAttribute("data-cost"))/100;
    total_cost =  e.target.getAttribute("data-cost")
    console.log(total_cost+'ggggggggggggggggg');
    let status = 0;
    console.log('today', expiry_date, " and ", created_at);

    let invoiceData = {
      created_at,
      expiry_date,
      per_amount,
      selectedCarrier,
      selectedMonth,
      status,
      carrier_name,
      carrier_email
    };
    // let invoiceData = {
    //   created_at,
    //   expiry_date,
    //   per_amount: 10,
    //   carrier_id: selectedCarrier,
    //   month: selectedMonth,
    //   status,
    //   carrier_name,
    //   carrier_email
    // };
    console.log('invoiceData', invoiceData);
    this.props.generate_invoice(invoiceData);
  }

  render() {
 
    console.log(this.props.data.carriers);
    const { selectedMonth, selectedCarrier } = this.state;
    return (
      <div>
        <NavBar />
        <div className="container-fluid pt-5">

          <div className="row">
            {/* heading of card start*/}

            <div className="col-md-8 offset-md-2">
              <span className="main_title">Carriers Payment</span>
              <span className="total_numbers ml-3">
                Total {this.state.allshow && this.state.data.length}
                {this.state.filtershow && this.state.filterrecord.length}
              </span>

              <div className="container bg-white pt-3 pl-3 pb-3 pr-3 shadow-sm">
                <div className="row">
                  <div className="col-6">
                    <span className="gray_text">CARRIER NAME </span>
                    <select name="selectedCarrier" id="selectedCarrier" className='form-control' value={selectedCarrier} onChange={this.handleChange}>
                      {this.props.data.carriers &&
                        this.props.data.carriers.map(item => (
                          // <option key={item._id} value={item._id} data-name={item.carrier_name} data-email={item.carrier_email}>{item.carrier_name}</option>
                          <option key={item._id} value={item._id}>{item.carrier_name}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-6">
                    <span className="gray_text">PAYMENT MONTH </span>
                    <select name="selectedMonth" className='form-control' id="selectedMonth" value={selectedMonth} onChange={this.handleChange}>
                      {
                        months.map((month) => (
                          <option key={month.value} value={month.value}>{month.label}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {total_cost !== 0 ?
                <div className="row mt-3">
                  <div className="col-4" align="left">
                    <span>
                      <b>Total Cost : </b> {total_cost}
                    </span>
                  </div>
                  <div className="col-4" align="left">
                    <span>
                      <b>Total Cost Percentage: </b> {(total_cost / 100) * 10}
                    </span>
                  </div>
                  <div className="col-4" align="center">
                    <button
                      className="nobg_btn_purple p-3"
                      data-cost={total_cost}
                      onClick={this.generateInvoice}
                    >
                      Generate Invoice
                      </button>
                  </div>
                </div>
                :
                null
              }
              </div>

              

              <TitleRow change1="EMAIL" change2="ADDRESS" />
              <div
                style={{
                  maxHeight: "80vh",
                }}
                className='hideoverflow'
              >
                {/* all data show */}
                <div
                style={{
                  display: "none",
                }}>{
              
              total_cost = 0 }</div>
                {this.state.allshow &&
                this.props.selectedData.selectedCarriers.length > 0 && 
                  this.props.selectedData ?
                  
                  this.props.selectedData.selectedCarriers.map(item => {
                    const pmntDate = new Date(item.payment_date);
                    const pmnTyear = new Date(item.payment_date);
                    const currentDate = new Date();
                    console.log('transaction date', pmnTyear.getFullYear(), ' ', currentDate.getFullYear());
                    if ((pmntDate.getMonth() + 1) == selectedMonth &&
                      pmnTyear.getFullYear() === currentDate.getFullYear()
                    ) {
                      total_cost = total_cost + parseFloat(item.cost);
                      return <CardCCP
                        key={item._id}
                        id={item.carrier_id._id}
                        carrier_name={item.carrier_id.carrier_name}
                        customer_name={item.customer_id.customer_name}
                        customer_id={item.customer_id._id}
                        email={item.carrier_id.carrier_email}
                        mobilenum={item.carrier_id.carrier_phone}
                        cost={item.cost}
                      />;
                    }
                    //  else {
                    //   return (<div className="bg-white shadow-sm rounded my-3">
                    //     <div className="row">
                    //       <p>No data found the selected Month. Select another month.</p>
                    //     </div>
                    //   </div>)
                    // }
                  })
                  :
                  <div>
                    <p>No data found. Please select Carrier Name and Month.</p>
                  </div>
                }

              </div>
              {/* {total_cost !== 0 ?
                <div className="row">
                  <div className="col-6" align="left">
                    <span>
                      <b>Total Cost : </b> {total_cost}
                    </span>
                  </div>
                  <div className="col-6" align="left">
                    <span>
                      <b>Total Cost Percentage: </b> {(total_cost / 100) * 10}
                    </span>
                  </div>
                </div>
                :
                null
              } */}
              {/* <div className="row">
                <div className="col-12" align="center">
                  <button
                    className="button_active"
                    onClick={this.handleGetCarrier}
                  >
                    Get Carrier
                      </button>
                </div>
              </div> */}
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.carriers_reducer,
    selectedData: state.carriers_reducer
  }
}
const mapDispatchToProps = dispatch => {
  return {
    get_carriers_payment: () => dispatch(get_carriers_payment_async()),
    generate_invoice: (invoiceData) => dispatch(generate_invoice_async(invoiceData)),
    get_selected_carriers_payment: (carrierId, monthId) => dispatch(get_selected_carriers_payment_async(carrierId, monthId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(carriers_payments);