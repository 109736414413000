import React from 'react'
import Crud from '../../Common/Crud/Crud'
import NavBar from '../../Common/NavBar'
import * as yup from 'yup';
export default function Carriers() {
    return (
        <div>
           <NavBar/>
           <Crud
           queryString='carriers'
           getUrl="crud/carriers"
           action="CARRIERS"
           title='Carriers'
           reducer='carriers_reducer'
           reduxSelector='carriers'
           schema={[
            {label:"Name",dbvalue:"carrier_name",show:{get:true,add:true,edit:true}},
            {label:"id",dbvalue:"_id",show:{get:false,add:false,edit:true}},
            {label:"Email",dbvalue:"carrier_email",show:{get:true,add:true,edit:true}},
            {label:"Company Name",dbvalue:"company_name",show:{get:true,add:true,edit:true}},
            {label:"VAT ",dbvalue:"vat_number",show:{get:true,add:true,edit:true}},
            {label:"Tax Office",dbvalue:"tax_office",show:{get:true,add:true,edit:true}},
            {label:"Mobile Phone",dbvalue:"carrier_phone",show:{get:true,add:true,edit:true}},
            {label:"Password",dbvalue:"carrier_password",show:{get:false,add:true,edit:false}},
                ]}
         formValues={{}}
         validationSchema = { 
            yup.object().shape({
            carrier_name: yup.string().required(),
            carrier_email: yup.string().email().required(),
            carrier_phone: yup.string().required(),
            //carrier_password:yup.string().required()
          })}
           />
           
        </div>
    )
}
