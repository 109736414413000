import loginAdmin from "../../Services/loginAdmin";

//-------------------------Admin LOGIN---------------
export const AdminLogin = (
  requestbody,
  config,
  LoginSucessAdmin,
  LoginFailureAdmin
) => {
  return (dispatch) => {
    //axios service call here and pass dispatch args
    loginAdmin(
      requestbody,
      config,
      dispatch,
      LoginSucessAdmin,
      LoginFailureAdmin
    );
  };
};