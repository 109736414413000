import React,{useEffect} from 'react'
import { Spinner,Button,DrawerBody,Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Text,
  DrawerHeader,
  IconButton,
  Box
} from '@chakra-ui/react'
import {useQuery} from 'react-query'
import { getAdminUserId } from '../../LocalStorage/Auth'
import { getData } from '../../globals'
import { EmailIcon} from '@chakra-ui/icons'
import Row from '../../Common/Layout/Row'


export default function Notifications() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    
    const {data,isLoading}  = useQuery("notifications",()=>getData(`admin/notifications/${getAdminUserId().id}`))

    useEffect(() => {
       console.log(data) 
    }, [data])
    return (
      <>
        <IconButton ref={btnRef} onClick={onOpen} aria-label="Search database" icon={<EmailIcon />} />
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size='md'
          
        >
          <DrawerOverlay />
          <DrawerContent bgColor='#F5FBFF'>
            <DrawerCloseButton />
            <DrawerHeader>Notifications</DrawerHeader>
            <DrawerBody>
                {isLoading?<Spinner />
                :data && data?.notifications?.length==0 ?
                <Text> 0 Notifications</Text>
                :
                data && data?.notifications.map(notification=><NotificationCard key={notification._id} notification={notification}/>)
                }
            </DrawerBody>
            
          </DrawerContent>
        </Drawer>
      </>
    )
  }


 const NotificationCard =({notification})=>{
   
  var Difference_In_Time = new Date().getTime() - new Date(notification.createdAt).getTime();
  var Difference_In_Days = parseInt(Difference_In_Time / (1000 * 3600 * 24))
  var Difference_In_minutes = parseInt(Difference_In_Time / (1000 * 60 ))
  var Difference_In_seconds= parseInt(Difference_In_Time / (1000 ))
  var Difference_In_hours= parseInt(Difference_In_Time / (1000 * 3600 ))
  let timeAgo=''

  if(Difference_In_Days==0){
    if(Difference_In_hours==0){
      if(Difference_In_minutes==0){
        timeAgo = `${Difference_In_seconds} seconds ago`
      }else{
      timeAgo = `${Difference_In_minutes} minutes ago`
      }
    }else{
      timeAgo = `${Difference_In_hours} hours ago` 
    }
  }else{
    timeAgo = `${Difference_In_Days} days ago`  
  }
  

  return (
    <Box onClick={()=>window.location=`/shipmentinfo/${notification.shipment_id}`} rounded={'md'} borderWidth={1} borderColor='rgba(0,0,0,.05) ' bgColor='white' padding='1em'>
      <Text color='blue'>{notification.notification_title}</Text>
      <Row style={{justifyContent:'space-between'}}>
      <Text maxWidth='70%'>{notification.notification_content}</Text>
      <Text fontSize='sm' color='gray.500'>{timeAgo}</Text>
      </Row>
    </Box>
  )
 }