import React from 'react'
import { Switch } from "@chakra-ui/react"
export default function MySwitch({label,onChange,name,defaultChecked=false}) {
    return (
        <div className='d-flex flex-column'>
        <label className='gray_text'>{label}</label>
        <Switch size="lg" onChange={onChange} name={name} defaultChecked={defaultChecked}/>
        </div>
    )
}
