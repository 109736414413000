import React, { useState,useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const MyDatePicker= ({label='label',onChange,error,name,selectedDate}) => {
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    selectedDate && setStartDate(new Date(selectedDate))
  }, [])
  return (
      <div className='d-flex flex-column'>
    <label className='gray_text'>{label}</label>
    <DatePicker
    name={name}
    dateFormat="d-M-yyyy"
    className='form-control w-100'
    style={{borderColor:error&&"red",backgroundColor:error&&"rgba(255,0,0,.1"}}
    selected={startDate} 
    onChange={(date)=>{
      setStartDate(date)
      onChange(name,date)
        }} />
      {error && <p style={{color:'red'}}>{error}</p>}
     </div>
  );
};

export default MyDatePicker