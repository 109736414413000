import React, { Component } from 'react'
import ReactModal from 'react-modal';
import InputField from './../Common/InputField'
import {carrier_input_field_edit} from '../input_fields_data'
import * as yup from 'yup';
import { connect } from 'react-redux'
import { edit_carrier_async } from '../Redux/actions/carriers_actions_creators';

let schema = yup.object().shape({
    carrier_name: yup.string().required().label('Name'),
    carrier_email: yup.string().required('Email is required').email().label('Email'),
    carrier_phone: yup.number('Not a Number').required('Mobile Number is Required!').label("Mobile Number").positive()
  });
class EditModal extends Component {

  constructor(props) {
    super(props);
    this.state={
        errors:{}
    }
}

componentWillReceiveProps(nextProps){
  if(nextProps.selected_carrier_for_edit){
    const selected_carrier_for_edit ={nextProps};
    console.log(nextProps.selected_carrier_for_edit)
  this.setState({
    id:nextProps.selected_carrier_for_edit._id,
    carrier_name:nextProps.selected_carrier_for_edit.carrier_name,
    carrier_email:nextProps.selected_carrier_for_edit.carrier_email,
    carrier_phone:nextProps.selected_carrier_for_edit.carrier_phone})
  }
}

changeHandler = e => {
this.setState({ [e.currentTarget.name]: e.currentTarget.value });
};

  edit=()=>{
    let errors={}
    schema.validate(this.state,{abortEarly:false}).then((res)=>{
      this.setState({errors:{}})
      const body={
          id:this.state.id,
          carrier_name:this.state.carrier_name,
          carrier_email:this.state.carrier_email,
          carrier_phone:this.state.carrier_phone
      }
      this.props.edit_carrier(body,this.callback)
    }).catch((err)=> {
      if(err.inner){
      err.inner.map(err=>{
         errors[err.path] =err.message;
    });
  }
    this.setState({errors})
});
}
callback=(success)=>{
  if(!success){
      //alert(this.props.error_message)
      return false
  }
  this.props.onClose();
  this.setState({errors:{}})
}
 
    render() {
        return (
            <ReactModal
            id='split_modal'
            isOpen={this.props.show}
            onRequestClose={this.props.onClose}
            shouldCloseOnEsc={true}
            className='split_modal'
            overlayClassName='split_modal_overlay'
            shouldCloseOnOverlayClick={true}
          >
            <button  style={{position:'fixed',top:10,right:10,background:'transparent',border:0}}
            onClick={()=>this.props.onClose()}>
              <img style={{height:'2em',width:'2em'}}src={require("../Pics/close.png")}></img>
            </button>
            <h4 className='mt-3 ml-3'>Edit carrier</h4>
               <div className='d-flex flex-wrap flex-row col-12 mt-4'> 
               {this.props.selected_carrier_for_edit?
               carrier_input_field_edit.map(element => {
                return  <InputField  
                name={element.name}
                changeHandler={this.changeHandler}
                label_text={element.label}
                className='col-6'
                value={this.state[element.name]}
                type={element.type}
                error={this.state.errors[element.name]}
                 />  
               }):null}
                </div>  
                <button className='btn btn-primary col-3 ml-4' onClick={this.edit}>{this.props.loading ?'Loading...' :'Edit'}</button>
          </ReactModal>
        )
    }
}
const mapStateToProps=(state)=>{
  const mystate=  state.carriers_reducer
  return{
     error_message: mystate.error_message,
     loading: mystate.loading
      
  }
}
const mapDispatchToProps =dispatch=>{
  return {
      edit_carrier:(body,callback)=>dispatch(edit_carrier_async(body,callback))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(EditModal)