import React,{useState,useEffect} from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,Button,Input,FormControl,FormLabel,Field,FormErrorMessage
  } from "@chakra-ui/react"
import { Formik,useFormik } from 'formik';
import MyInput from '../../Forms/MyInput';

import useApiRequest from '../../../Services/useApiRequest';
import MySelect from '../../Forms/MySelect';
import MyDatePicker from '../../Forms/MyDatePicker';
import MySwitch from '../../Forms/MySwitch';

function Filters({schema,formValues,record,title,getUrl,action,validationSchema}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {loading,data,sendRequest:filterRecords} = useApiRequest({ url:getUrl, type:'get',action:`FILTER_${action}`});
  const btnRef = React.useRef()
  const formik = useFormik({
    initialValues: {...formValues},
    onSubmit: body =>{

    let url = getUrl
    console.log(body)
    Object.keys(body).map((key,index)=>{
        if(body[key]!=''){
        url+=`${url.slice(0,-1)!='?' ? '?':'&&'}${key}=${body[key]}`
        }
    })
    console.log(url)
    filterRecords({url})
    }
  });

  return (
    <>
      <Button ref={btnRef} size='lg' colorScheme="purple" onClick={onOpen}>Filters</Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size='md'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Filter {title}</DrawerHeader>
            <DrawerBody>
            <form onSubmit={formik.handleSubmit}>
            {schema.map(s=>{
                  if(s.show.add){
                      switch (s.type) {
                          case "select":
                             return <MySelect isFetching={true} queryString={s?.queryString} getUrl={s.getUrl} label1={s.selectLabel1} label2={s.selectLabel2} label={s.label} onChange={formik.setFieldValue} name={s.dbvalue} /> 
                        case "date":
                           return <MyDatePicker label={s.label} onChange={formik.setFieldValue} id={s.dbvalue} name={s.dbvalue} error={formik.errors[s.dbvalue]}/>
                        case "boolean":
                          return <MySwitch label='Approved' defaultChecked={formik.initialValues[s.dbvalue]} onChange={formik.handleChange} name={s.dbvalue} />
                          default:
                            return <MyInput label={s.label} value={formik.values[s.dbvalue]}  onChange={formik.handleChange} id={s.dbvalue} name={s.dbvalue} error={formik.errors[s.dbvalue]}/>
                              break;
                      }
                  }
              })}
             
               
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" type='submit'>Filter</Button>
            </DrawerFooter>
          </form>  
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
    )
}

export default Filters 

