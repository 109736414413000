
  import React,{useState,useEffect} from 'react'
import {useDispatch} from 'react-redux'
import axios from 'axios'
import { URL } from '../globals';
import { useToast } from "@chakra-ui/react"
import { createAction} from '@reduxjs/toolkit'

export default function useApiRequest (config) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch()
    const toast = useToast()

    const sendRequest = (params) => {
        console.log(config)
        let request;
        if(config.type=='get'){
             request =  axios.get(`${URL}${params?.url? params?.url :config.url}`)
        }else if(config.type=='post'){
            request =  axios.post(`${URL}${config.url}`,params?.body)
        }
        else if(config.type=='put'){
            console.log("body",params.body)
            request =  axios.put(`${URL}${config.url}`,params?.body)
        } else if(config.type=='delete'){
            request =  axios.delete(`${URL}${params?.url}`)
        }

        setLoading(true)
        request
          .then(response => {
            setLoading(false)
            
            if(config?.action){
             const actions  = createActions(config?.action.toUpperCase())
             dispatch(actions.success(response))
            }
            setData(response.data);
          })
          .catch(error => {
            setLoading(false)
            if (error.response) {
        
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.log("Error", error.message);
        
                console.log("error 1");
              } else if (error.request) {
                // No Response
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            setError(error);
          });
      };
    useEffect(() => {
        
    if(config?.now){
        sendRequest();
    }
    }, []);
  
    return { error, loading, data, sendRequest };
  };
  export const createActions= (namespace) =>{

    const start= `${namespace}_START`;
    const success= `${namespace}_SUCCESS`;
    const fail= `${namespace}_FAIL`
  
    const action_start= createAction(start);
    const action_success= createAction(success);
    const action_fail= createAction(fail);
  
    return {
     start:action_start,
     success:action_success,
     fail:action_fail
    };
  }