import React, { Component } from "react";
import "../Styles/NavBar.css";
import {connect} from 'react-redux'
import { select_carrier_for_edit } from "../Redux/actions/carriers_actions";
class CardCC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: false
    };
  }
  hanldeChange = () => {
    if (this.props.title === "Customers") {
      return this.setState({ customer: true });
    }
    return this.setState({ customer: false });
  };
  componentDidMount() {
    this.hanldeChange();
  }

  render() {
    const { item } = this.props;
    return (
      <div className="container bg-white my-3 p-2 shadow-sm rounded">
        <div className="row">
          <div className="col-6 d-flex">
          <img
            src={require('../Pics/profile.png')}
            alt="pic"
            style={{ width: "50px", height: "50px",marginRight:5 }}
          />
            <div className="d-flex flex-column justify-content-center ml-1">
              <span className="black_text bold">{this.props.name}</span>
              <span className="blue_text bold">ID: {this.props.id}</span>
            </div>
          </div>
          <div className="col-2 d-flex align-items-center">
            <span className="black_text bold">{this.props.mobilenum}</span>
          </div>
          <div className="col-2 d-flex align-items-center">
            <span className="black_text bold">{this.props.email}</span>
          </div>
          <div className="col-2 d-flex align-items-center">
            <button onClick={this.props.handleEdit} className='btn nobg_btn_purple mr-2'>Edit</button> 
            <button className='btn nobg_btn_red'>Delete</button> 
           </div>
         
        </div>
      </div>
    );
  }
}



const mapDispatchToProps =dispatch=>{
  return {
  //  select_carrier_for_edit:(id)=>dispatch(select_carrier_for_edit(id))
  }
}

export default connect(null,mapDispatchToProps)(CardCC);