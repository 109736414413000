import React, { Component } from 'react'
import ReactModal from 'react-modal'
import InputField from '../Common/InputField'
import {carrier_add_input_field} from '../input_fields_data'
import * as yup from 'yup';

//redux 
import { connect } from 'react-redux'
import { add_carrier_async } from '../Redux/actions/carriers_actions_creators';
import { api_callback } from '../globals';


  let schema = yup.object().shape({
    carrier_name: yup.string().required().label('Name'),
    carrier_password: yup.string().required().label('Password'),
    carrier_password_re: yup.string().required().label('Confirmation Password').required().oneOf([yup.ref('carrier_password'), null], 'Passwords must match'),
    carrier_email: yup.string().required('Email is required').email().label('Email'),
    carrier_phone: yup.number('Not a Number').required('Mobile Number is Required!').label("Mobile Number").positive()
  });

  
 class AddModal extends Component  {
        constructor(props) {
            super(props);
            this.state={
                errors:{}
            }
        }
     
     changeHandler = e => {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
      };

      
        add=()=>{
            let errors={}
            schema.validate(this.state,{abortEarly:false}).then((res)=>{
              this.setState({errors:{}})
              const body={
                  carrier_name:this.state.carrier_name,
                  carrier_email:this.state.carrier_email,
                  carrier_password: this.state.carrier_password,
                  carrier_phone:this.state.carrier_phone
              }
              this.props.add_carrier(body,this.callback)
            }).catch((err)=> {
              err.inner.map(err=>{
                 errors[err.path] =err.message;
            });
            this.setState({errors})
        });
    }
    callback=(success)=>{
        if(!success){
          api_callback(false,'Error Occured',this.props.error_message)
            return false
        }
        api_callback(true, 'Success','Success') 
        this.props.onClose()
        this.setState({errors:{}})
    }
       
    

      render() {

          const {show}=this.props
        return (
       <ReactModal
        id='split_modal'
        isOpen={show}
        onRequestClose={() => this.props.onClose()}
        shouldCloseOnEsc={true}
        className='split_modal'
        overlayClassName='split_modal_overlay'
        shouldCloseOnOverlayClick={true}
      >
        <button  style={{position:'fixed',top:10,right:10,background:'transparent',border:0}}
        onClick={()=>this.props.onClose()}>
          <img style={{height:'2em',width:'2em'}}src={require("../Pics/close.png")}></img>
        </button>
        <h4 className='mt-3 ml-3'>Add Carrier</h4>
           <div className='d-flex flex-wrap flex-row col-12 mt-4'> 
           {
           carrier_add_input_field.map(element => {
            return   <InputField  
              name={element.name}
              label='label'
              className='col-6'
              label_text={element.label}
              type={element.type}
              changeHandler={this.changeHandler}
              error={this.state.errors[element.name]}
             />  
           })
          }
            </div>  
            <button className='btn btn-primary col-3 ml-4' onClick={this.add}>{this.props.loading ?' Loading...' : 'Create'}</button>
      </ReactModal>
        )
    }
}

const mapStateToProps=(state)=>{
    const mystate=  state.carriers_reducer
    return{
       error_message: mystate.error_message,
       loading: mystate.loading
        
    }
  }
  const mapDispatchToProps =dispatch=>{
    return {
        add_carrier:(body,callback)=>dispatch(add_carrier_async(body,callback))
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(AddModal)

