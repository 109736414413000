import {get_shipment_action,loading, start_request, get_shipment_by_id_action,add_carriers_to_shipment_action, get_offers_action, approve_offer_action} from './shipment_actions'
import axios from 'axios';
import AddShipmentCustomer from "../../Services/AddShipmentCustomer";
import EditShipmentCustomer from "../../Services/EditShipmentCustomer";
import { api_callback, URL } from '../../globals';

export const get_shipments_async=()=>{
    return (dispatch)=>{
        dispatch(start_request())
        axios
         .get(`${URL}admin/shipments/`)
         .then((response)=> {
           dispatch(get_shipment_action(response.data.data.shipments,response.data.resultcount));
         //   console.log(response.data);
            
            //AddDriverSucess();
         })
         .catch(function(error) {
           if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log("Error", error.message);
            console.log("error 1");
           } else if (error.request) {
            console.log(error.request);
            } else {
             console.log("Error", error.message);
            }
             console.log(error.config);
              });
          };;
        

    }

    export const get_shipment_by_id_async=(id,callback)=>{
      return (dispatch)=>{
          dispatch(start_request())
          axios
           .get(`${URL}admin/shipments/${id}`)
           .then((response)=> {
              dispatch(get_shipment_by_id_action(response.data.data.shipment[0]));
              callback(true)
              //AddDriverSucess();
           })
           .catch(function(error) {
             if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log("Error", error.message);
              console.log("error 1");
             } else if (error.request) {
              console.log(error.request);
              } else {
               console.log("Error", error.message);
              }
               console.log(error.config);
                });
            };;
      }

      export const add_carriers_to_shipment=(id,body,callback)=>{
         return (dispatch)=>{
             dispatch(start_request())
             axios
              .post(`${URL}shipments/${id}/addcarriers`,body)
              .then((response)=> {
                 console.log(response.data.data.shipment)
                 dispatch(add_carriers_to_shipment_action(id,response.data.data.shipment));
                 callback(true);
              })
              .catch(function(error) {
                if (error.response) {
                  api_callback(false,"fe","fe")
                  callback(false);
                 console.log(error.response.data);
                 console.log(error.response.status);
                 console.log(error.response.headers);
                 console.log("Error", error.message);
                 console.log("error 1");
                } else if (error.request) {
                 console.log(error.request);
                 } else {
                  console.log("Error", error.message);
                 }
                  console.log(error.config);
                   });
               };;
         }
         export const add_carriers_to_shipment2=(id,body,callback)=>{
              axios
               .post(`${URL}shipments/${id}/addcarriers`,body)
               .then((response)=> {
                  console.log(response.data.data.shipment)
                  callback(true);
               })
               .catch(function(error) {
                 if (error.response) {
                   api_callback(false,"fe","fe")
                   callback(false);
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                  console.log("Error", error.message);
                  console.log("error 1");
                 } else if (error.request) {
                  console.log(error.request);
                  } else {
                   console.log("Error", error.message);
                  }
                   console.log(error.config);
                    });
                };
         export const get_offers_async=(shipment_id,callback)=>{
            return (dispatch)=>{
                axios
                 .get(`${URL}admin/shipments/${shipment_id}/offers`)
                 .then((response)=> {
                    dispatch(get_offers_action(response.data.data.offers,shipment_id));
                    callback(true);
                    //AddDriverSucess();
                 })
                 .catch(function(error) {
                   if (error.response) {
                      callback(false);
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    console.log("Error", error.message);
                    console.log("error 1");
                   } else if (error.request) {
                    console.log(error.request);
                    } else {
                     console.log("Error", error.message);
                    }
                     console.log(error.config);
                      });
                  };;
                
        
            } 
            export const approve_offer_async=(id,body,callback)=>{
               return (dispatch)=>{
                   dispatch(start_request())
                   axios
                   .post(`${URL}admin/shipments/${id}/offers`,body)
                    .then((response)=> {
                       console.log(response)
                      // dispatch(approve_offer_action(id,));
                       callback(true);
                    })
                    .catch(function(error) {
                      if (error.response) {
                        callback(false);
                       console.log(error.response.data);
                       console.log(error.response.status);
                       console.log(error.response.headers);
                       console.log("Error", error.message);
                       console.log("error 1");
                      } else if (error.request) {
                       console.log(error.request);
                       } else {
                        console.log("Error", error.message);
                       }
                        console.log(error.config);
                         });
                     };;
               }
			   
			   /****add shipment*****/
			   export const AddShipmentCustomerSide = (
			  requestbody,
			  config,
			  AddShipmentSucessCustomer,
			  AddShipmentFailureCustomer
			) => {
			  return (dispatch) => {
				AddShipmentCustomer(
				  requestbody,
				  config,
				  dispatch,
				  AddShipmentSucessCustomer,
				  AddShipmentFailureCustomer
				);
			  };
			};

          /******add shipment end***************/
		  
		   /****edit shipment*****/
			   export const EditShipmentCustomerSide = (
			  requestbody,
			  config,
			  EditShipmentSucessCustomer,
			  EditShipmentFailureCustomer
			) => {
			  return (dispatch) => {
				EditShipmentCustomer(
				  requestbody,
				  config,
				  dispatch,
				  EditShipmentSucessCustomer,
				  EditShipmentFailureCustomer
				);
			  };
			};

export const delete_shipment_async=(id,callback)=>{
    axios
     .delete(`${URL}admin/shipments/${id}`)
     .then((response)=> {
      console.log("Delete Shipment",response)
      callback(true);
     })
     .catch(function(error) {
      if (error.response) {
       api_callback(false,"Error","Error")
       callback(false);
       console.log(error.response.data);
       console.log(error.response.status);
       console.log(error.response.headers);
       console.log("Error", error.message);
       console.log("error 1");
      } else if (error.request) {
       console.log(error.request);
      } else {
       console.log("Error", error.message);
      }
       console.log(error.config);
      });
   };