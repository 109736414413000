import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Card extends Component {
    render() {
        const {carrier} =this.props
        if(carrier){
        return (
            <div className="container bg-white my-3 p-2 shadow-sm rounded">
            <div className="row">
              <div className="col-6 d-flex">
              <img
                src={require('../Pics/profile.png')}
                alt="pic"
                style={{ width: "50px", height: "50px",marginRight:5 }}
              />
                <div className="d-flex flex-column justify-content-center ml-1">
                  <span className="black_text bold">{carrier.carrier_name}</span>
                  <span className="blue_text bold">ID: {carrier._id}</span>
                </div>
              </div>
              <div className="col-2 d-flex align-items-center">
                <span className="black_text bold">{carrier.carrier_phone}</span>
              </div>
              <div className="col-2 d-flex align-items-center">
                <span className="black_text bold">{carrier.carrier_email}</span>
              </div>
              <div className="col-2 d-flex align-items-center">
                <button onClick={this.props.handleEdit} className='btn nobg_btn_purple mr-2'>Edit</button> 
                <button onClick={this.props.handleDelete} className='btn nobg_btn_red'>Delete</button> 
               </div>
             
            </div>
          </div>
        )
    
  }else{
    return null
  }
}
}


const mapStateToProps = (state) => ({
   loading:state.carriers_reducer.loading
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Card)
