import React, { Component } from "react";
import CarriersData from "./CarriersData";
import CardCC from "../Common/CardCC";
import PlusButton from "../Common/PlusButton";
import TitleRow from "../Common/TitleRow";
import FilterModule from "../Customers/FilterModule";
import {connect} from 'react-redux'
import {get_carriers_async, add_carrier_async,delete_carrier_async} from '../Redux/actions/carriers_actions_creators'
import AddModal from './AddModal'
import EditModal from './EditModal'
import {add_input_field,filter} from '../input_fields_data'
import Card from './Card'
import NavBar from '../Common/NavBar'
const Label_Info = [
  { label_text: "Carrier ID", label: true, field_name: "_id" },
  { label_text: "Carrier Email", label: true, field_name: "carrier_email" },
  { label_text: "Address", label: true, field_name: "carrier_phone" },
  { label_text: "Type of Truck", label: true, field_name: "carrier_name" }
];


 class Carriers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstfield: "",
      secondfield: "",
      address: "",
      type: "",
      data: CarriersData,
      filterrecord: [],
      allshow: true,
      filtershow: false,
      add_modal_visibility:false,
      edit_modal_visibility:false,
      selected_carrier_for_edit:null
    };
  }
  componentDidMount(){
    this.props.get_carriers();
  }
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  
  deleteCarrier=(id)=>{
    this.props.delete_carrier(id,this.callback)
   }
  //after clicking the search button..find record just base on address
  searchRecord = () => {
    this.setState(filter(Label_Info,this.props.data.carriers,this.state)); 
   };
  render() {
    console.log(this.state);
  
    return (

<div>
  <NavBar/>

      <div className="container-fluid pt-5">
	
        <div className="row">
       
          <div className="col-md-8 offset-md-1">
            <span className="main_title">Carriers</span>
            <span className="total_numbers ml-3">
              {this.props.filter_applied ? this.props.filtered_carriers.length : this.props.carriers.length} Total
            </span>
            <TitleRow change1="Telephone" change2="Address" />
            <div
              style={{
                maxHeight: "80vh",
              }}
              className='hideoverflow'
            >
              {this.props.filter_applied ?
                this.props.filtered_carriers.map(item => {
                  return <Card 
                  handleEdit={()=>this.setState({selected_carrier_for_edit:item,editModal_show:true})}
                  handleDelete={()=>this.deleteCarrier(item._id)}
                  carrier={item} />;
                }): this.props.carriers &&
                this.props.carriers.map(item => {
                  return <Card 
                  handleEdit={()=>this.setState({selected_carrier_for_edit:item,editModal_show:true})} 
                  handleDelete={()=>this.deleteCarrier(item._id)}
                  carrier={item} />;
                })}
            </div>
          </div>
          <div className="col-md-3">
            <FilterModule
              title="Carrier Filter"
              state={this.state}
              Label_Info={Label_Info}
              changeHandler={this.changeHandler}
              searchRecord={this.searchRecord}
              data={this.props.carriers}
              type='carrier'
            />
            <PlusButton onClick={()=>this.setState({addModal_show:true})} />
            <AddModal show={this.state.addModal_show} onClose={()=> this.setState({addModal_show:false})} />
           <EditModal show={this.state.editModal_show} selected_carrier_for_edit={this.state.selected_carrier_for_edit} onClose={()=> this.setState({editModal_show:false})}/>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps=(state)=>{
  return{
    carriers:state.carriers_reducer.carriers,
    filtered_carriers:state.carriers_reducer.filtered_carriers,
    filter_applied:state.carriers_reducer.filter_applied,
  }
}
const mapDispatchToProps =dispatch=>{
  return {
    get_carriers:()=>dispatch(get_carriers_async()),
    add_carrier:body=>dispatch((add_carrier_async(body))),
    delete_carrier:(id,callback)=>dispatch(delete_carrier_async(id,callback))

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Carriers);