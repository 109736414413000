import React, { Component } from 'react'
import InputField from './InputField';
import { connect } from 'react-redux'
import Select from 'react-select'
import '../Styles/react_dates_overrides.css'
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';// PropTypes.func.isRequired,
import MySelect from '../Common/Forms/MySelect'

import dateformat from 'dateformat'
import { filter_admin_shipments } from '../Redux/actions/shipment_actions';
import { FaThumbsDown } from 'react-icons/fa';
const states = [
    { value: 'all', label: 'All' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'upcoming', label: 'Upcoming' },
    { value: 'inprogress', label: 'In Progress' },
    { value: 'completed', label: 'Completed' },
  ]
  const shipment_type = [
    { value: 'all', label: 'All' },
    { value: 'ftl', label: 'FTL' },
    { value: 'ltl', label: 'LTL' },
  ]

 class ShipmentsFilter extends Component {

    constructor(props){
        super(props);
        this.state = {
            description:'',
            status:'all',
            type:'all',
            shipment_type:'all',
            customer_id:'',
            date:[new Date(),new Date()]
        }
    }

    filter=(e)=>{
        e.preventDefault()

        const pickup_date =[new Date(dateformat(this.state.pickup_date_start,'yyyy-m-d')),new Date(dateformat(this.state.pickup_date_end,'yyyy-m-d'))]
        const dropoff_date =[new Date(dateformat(this.state.dropoff_date_start,'yyyy-m-d')),new Date(dateformat(this.state.dropoff_date_end,'yyyy-m-d'))]
        const {description,status,shipment_type} =this.state;
        const {shipments}=this.props;
        let myshipments = shipments
        .filter(s=> s.shipment_description.toLowerCase().includes(description.toLowerCase()))
        .filter(s=> s.customer_id==this.state.customer_id)
        .filter(s=> s.shipment_status.toLowerCase()==status.toLowerCase()?true: status.toLowerCase()=='all'?true:false)
        .filter(s=> s.shipment_type.toLowerCase()==shipment_type.toLowerCase()?true: shipment_type.toLowerCase()=='all'?true:false)
        .filter(s=> this.state.pickup_date_start ? new Date(s.pickup_date) >=new Date(pickup_date[0]) && new Date(s.pickup_date) <= new Date(pickup_date[1]):true)
        .filter(s=> this.state.dropoff_date_start ?  new Date(s.dropoff_date) >=new Date(dropoff_date[0]) && new Date(s.dropoff_date) <= new Date(dropoff_date[1]):true)
        console.log(myshipments)

        this.props.setfiltered(true)
        this.props.setShipments(myshipments)

       
        //write on shipments state
            this.props.filter_admin_shipments(myshipments)

    }
    renderInput=(label, fieldname, type = "text") =>{
        return (
          <InputField
            name={fieldname}
            label_text={label}
            typename={type}
            changeHandler={this.changeHandler}
            value={this.state[fieldname]}
          />
        );
      }

      changeHandler = ({ target }) => {
         this.setState({[target.name]:target.value});
         console.log(target)
       }
       changeSelectHandler =(name,value)=>{
        this.setState({[name]:value})
       }

    render() {
        
        return (
            <div className='col-md-2 col-sm-12 d-flex bg-white shadow-sm justify-content-center pb-4 p-3' style={{height:'fit-content'}}>
                <form className='w-100' onSubmit={this.filter}>
                    <p  style={{fontWeight:600,fontSize:'1.3em'}}className='black_text text-bold'>Filters</p>
                    {this.renderInput('Description','description')}
                    <div className='mt-4'>
                    <label className='gray_text'>Status</label>
                    <Select options={states} name='status' onChange={(e)=>this.setState({status:e.value})}/>
                    </div>
                    
                    <MySelect 
                    isFetching
                    label='Customer'
                    getUrl='crud/customers'
                    queryString='customers'
                    label1='customer_name'
                    label2='customer_email'
                    name='customer_id'
                    onChange={this.changeSelectHandler}
                    />

                    <div className='mt-4'>
                    <label className='gray_text'>Pickup Date</label>
                    <DateRangePicker
                    isOutsideRange={() => false}
                    displayFormat='DD-MM-YYYY'
                    startDate={this.state.pickup_date_start} 
                    startDateId='pickup_date_start' 
                    endDate={this.state.pickup_date_end} 
                    endDateId="pickup_date_end" 
                    onDatesChange={(dates) =>{
                     this.setState({ pickup_date_start:dates.startDate,pickup_date_end: dates.endDate})
                    }} 
                    focusedInput={this.state.focusedInput} 
                    onFocusChange={focusedInput => this.setState({ focusedInput })} 
                    /> 
                    </div>
                    <div className='mt-4'>
                    <label className='gray_text'>Dropoff Date</label>
                    <DateRangePicker
                    isOutsideRange={() => false}
                    displayFormat='DD-MM-YYYY'
                    startDate={this.state.dropoff_date_start} 
                    startDateId='dropoff_date_start' 
                    endDate={this.state.dropoff_date_end} 
                    endDateId="dropoff_date_end" 
                    onDatesChange={(dates) =>{
                     this.setState({ dropoff_date_start:dates.startDate, dropoff_date_end: dates.endDate})
                    }} 
                    focusedInput={this.state.focusedInput2} 
                    onFocusChange={focusedInput2 => this.setState({ focusedInput2 })} 
                    /> 
                    </div>
                    
                    <button className='btn btn-primary w-100 mt-4' type='submit'>Submit</button>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = (dispatch) => {
    return {
        filter_admin_shipments:(shipments)=>dispatch(filter_admin_shipments(shipments)), 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsFilter)