import React, { Component } from 'react'
import ReactModal from 'react-modal';
import {connect} from 'react-redux'
 class SplitModal extends Component {
   constructor(props) {
      super(props);
      this.state = {
      };
    }
    
   render() {
      return (
        <ReactModal
        id='split_modal'
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        shouldCloseOnEsc={true}
        className='split_modal'
        overlayClassName='split_modal_overlay'
        shouldCloseOnOverlayClick={true}
      >
           <p className='mt-3 ml-2 gray_text bold '>SPLIT MODAL</p>
        <button  style={{position:'fixed',top:10,right:10,background:'transparent',border:0}}
        onClick={this.props.onClose}>
          <img style={{height:'2em',width:'2em'}}src={require("../Pics/close.png")}></img>
          </button>
          
          {this.props.split_modal_later==null?
           (
            <div className='container row p-4 justify-content-center'>
            <button onClick={()=>this.props.handleSplit(true)} className='btn btn-secondary col-5 mr-4'>Later</button>
            <button onClick={()=>this.props.handleSplit(false)} className='btn btn-primary col-5'>Now</button>
           </div>
          )
          : this.props.split_modal_later==true? 
          <div className='container row p-4 justify-content-center'>
            <h4>Later</h4>
           </div>
          : 
          <div className='container row p-4 justify-content-center'>
            <h4>Now</h4>
           </div>
          }
      </ReactModal>
      )
   }
}
const mapStateToProps=(state)=>{
   return{
     data:state.shipments_reducer,
     carriers_data:state.carriers_reducer,
   }
 }

 
 export default connect(mapStateToProps,null)(SplitModal);