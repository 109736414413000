import { GET_CUSTOMERS, START_REQUEST, ADD_CUSTOMER_SUCCESS, ADD_CUSTOMER_START, ADD_CUSTOMER_FAIL, DELETE_CUSTOMER_START, DELETE_CUSTOMER_FAIL, DELETE_CUSTOMER_SUCCESS, EDIT_CUSTOMER_START, EDIT_CUSTOMER_FAIL, EDIT_CUSTOMER_SUCCESS, FILTER_CUSTOMERS } from "../constants";


const initialstate = {
  customers: [
  ],
  filter_applied:false,
  filtered_customers:[],
  customers_count: null,
  loading:false,
  show_filtered_shipments:false,
  show_all_shipments:true,
  error_message:''
  };
  
  const customers_reducer = (state = initialstate, action) => {
    if (action.type === GET_CUSTOMERS) {
      return {
        ...state,
       customers:action.payload,
       customers_count:action.count,
       loading:false
      };
    }
    if (action.type === START_REQUEST) return { ...state,loading:true}

    if (action.type=== ADD_CUSTOMER_START) return { ...state,loading:true}
    if (action.type=== ADD_CUSTOMER_FAIL) return { ...state,loading:false,error_message:action.error_message}
    if (action.type=== ADD_CUSTOMER_SUCCESS){
        let customers = state.customers;
        customers.push(action.payload)
       return { ...state,loading: false,customers }
      }

      if (action.type=== DELETE_CUSTOMER_START) return { ...state,loading:true}
      if (action.type=== DELETE_CUSTOMER_FAIL) return { ...state,loading:false,error_message:action.error_message}
      if (action.type=== DELETE_CUSTOMER_SUCCESS){
          let customers = state.customers;
        customers=  customers.filter(customer=> customer._id!= action.payload)
         return { ...state,loading: false,customers }
        }

        if (action.type=== EDIT_CUSTOMER_START) return { ...state,loading:true}
        if (action.type=== EDIT_CUSTOMER_FAIL) return { ...state,loading:false,error_message:action.error_message}
        if (action.type=== EDIT_CUSTOMER_SUCCESS){
            let customers = state.customers;
            console.log(action.payload)

          customers = customers.map( customer =>{
           if( customer._id===action.payload._id ){
              return action.payload
            }else{
              return customer
            } 
          });
          console.log(customers)

           return { ...state,loading: false,customers }
          }
          if (action.type=== FILTER_CUSTOMERS) return { ...state,filtered_customers:action.filtered_customers,filter_applied:true}
    return state;
  };
  
  export default customers_reducer;
  