import React, { Component } from "react";

class InputField extends Component {
  render() {
    return (
      <div className='col-12'>
        <label
          className="font-weight-bolder"
          style={{ color: "#B0BAC9", fontSize: "15px" }}
        >
          {this.props.labeltitle}
        </label>
        <input
          type={this.props.typename}
          name={this.props.fieldname}
          className="form-control"
          value={this.props.value}
          onChange={this.props.changeHandler}
        />
        {this.props.error && (
          <p className="red_text d-block" style={{ fontSize: "14px",fontWeight:400,position:'relative',marign:0}}>
            {this.props.error}
          </p>
        )}
      </div>
    );
  }
}

export default InputField;
