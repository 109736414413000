import jwtDecode from "jwt-decode";
const Admin = "Admin_AuthToken";
const AdminObj = "Admin_Obj";

export function createLocalStorageAdmin(token, obj) {
  localStorage.setItem(Admin, token);
  //localStorage.setItem(AdminObj, JSON.stringify(obj));
}



export function removeToken() {
  localStorage.clear();
  window.location = "/";
}
export function getAdmin() {
  try {
    const jwt = localStorage.getItem(Admin);
    return jwt;
  } catch (ex) {
    return null;
  }
}

export function getAdminUserId() {
  try {
    const jwt = localStorage.getItem(Admin);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function AdminDetail(obj) {
  localStorage.setItem("Admin_Detail", JSON.stringify(obj));
}
export function getAdminDetail() {
  try {
    const obj = JSON.parse(localStorage.getItem("Admin_Detail"));
    return obj;
  } catch (ex) {
    return null;
  }
}
