import { ThemeProvider } from '@emotion/react'
import React, { Component,useEffect,useState } from 'react'
import Select from 'react-select'
import { getData } from '../../globals'
import useApiRequest from '../../Services/useApiRequest'
import {useQuery,useQueryClient} from 'react-query'

const MySelect = ({isFetching,getUrl,onChange,label1,label2,label,error,name,options,defaultValue,isMulti=false,queryString}) => {

  const queryClient = useQueryClient()
 
 const {data, isLoading,refetch} = useQuery(queryString,()=>getData(getUrl),{
  initialData: () => {
   return queryClient.getQueryData(queryString)
    },
    enabled:false
})


 const [fetchedOptions, setoptions] = useState([])
 const [defaultSelectValue, setDefaultSelectValue] = useState(null)


useEffect(() => {
    if(isFetching){
        refetch()
    }
}, [isFetching])

useEffect(() => {
   if(data){
       if(Array.isArray(data.data)){
        let temp =[]
       data?.data.map(data=>{
        temp.push({value:data._id,label:data[label1]+" - "+data[label2]})
       })
       setoptions(temp)
   } 
}
}, [data])

//calculate default value of select
useEffect(() => {
    let found = options ? options.findIndex (o=> o.value===defaultValue)  :fetchedOptions && fetchedOptions.findIndex (o=> o.value===defaultValue)
    if(found!=-1){
      setDefaultSelectValue(found)
    }else{ //not found
      setDefaultSelectValue(-1)
    }
}, [options,fetchedOptions])


if(defaultValue){
  if(defaultSelectValue!=null){
    return (
      <div>
      <label className='gray_text'>{label}</label>
        <Select 
        isMulti={isMulti}
        options={options||fetchedOptions}
        isLoading={isLoading}
        value={options?options[defaultSelectValue]:fetchedOptions[defaultSelectValue]}
        onChange={(e)=>{
          onChange(name,e.value)
          setDefaultSelectValue(e)
        }}
        style={{borderColor:error&&"red",backgroundColor:error&&"rgba(255,0,0,.1"}}
        />
        
         {error && <p style={{color:'red'}}>{error}</p>}
        </div>
      )
  }else{ 
    return <h3>fefe</h3>
  
  }
}else{
  return (
    <div>
    <label className='gray_text'>{label}</label>
    <Select 
        options={options||fetchedOptions}
        isLoading={isLoading}
        onChange={(e)=>{
          onChange(name,e.value)
        }}
        style={{borderColor:error&&"red",backgroundColor:error&&"rgba(255,0,0,.1"}}
        />
      
       {error && <p style={{color:'red'}}>{error}</p>}
      </div>
    )
}
 
}

export default MySelect;