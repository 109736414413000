import React,{useEffect,useState} from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    useDisclosure,
    Box,
    Spinner,
    Button,Stack, Skeleton,Badge

  } from "@chakra-ui/react"
import useApiRequest from '../../Services/useApiRequest';
import AddRecord from './AddRecord/AddRecord';
import EditRecord from './EditRecord/EditRecord';
import {useSelector,useDispatch} from 'react-redux'
import dateformat from 'dateformat'
import Filters from './Filters/Filter';
import {useQuery,QueryClient,useMutation} from 'react-query'
import axios from 'axios'
import { getData, URL } from '../../globals';
import DeleteRecord from './DeleteRecord/DeleteRecord';





export default function Crud({
  getUrl,action,reducer,reduxSelector,formValues,schema,title,validationSchema,queryString
}) {
    const queryClient = new QueryClient()
    const {data, isLoading} = useQuery(queryString,()=>getData(getUrl),{
      initialData: () => {
       return queryClient.getQueryData(queryString)
        }
    })
    const filteredData = queryClient.getQueryData(queryString+'_filters')
    //State
    const [editRecord, seteditRecord] = useState({})


    //--------    
    const { isOpen, onOpen, onClose } = useDisclosure()
   
    
    const {sendRequest:deleteRecord} = useApiRequest({ type:'delete',action:`DELETE_${action?.slice(0,-1)}`});
    const records = useSelector(state => state[reducer][reduxSelector]) 
    const filter_applied= useSelector(state => state[reducer].filter_applied) 
    const filtered_records= useSelector(state => state[reducer].filtered_records) 
    const dispatch = useDispatch()

   useEffect(() => {
     console.log(data)
   }, [data])

    return (
        <Box bgColor='white'   padding={3} margin={3}>
        <Stack spacing={10} marginBottom={3} direction="row" align="flex-end" justify='flex-end'>
        <AddRecord 
       schema={schema} 
       formValues={formValues} 
       title={title}
       getUrl={getUrl}
       action={action}
       validationSchema={validationSchema}
       postUrl={getUrl}
       queryString={queryString}
        />
     <Filters
       schema={schema} 
       formValues={formValues} 
       title={title}
       getUrl={getUrl}
       action={action}
       validationSchema={validationSchema}
       queryString={queryString+'_filters'}
        />
      <Button size='lg' onClick={()=>dispatch({type:`RESET_FILTERS_${title.toUpperCase()}`})}>Reset Filters</Button>
        </Stack>
      <Box maxHeight='80vh' overflowY='scroll' >

        <Table  variant="striped">
        <Thead  maxH="360px" background='teal'>
          <Tr style={{position:'relative'}}>
          {schema.map(s=>s.show.get &&<Th color='white' fontSize={12}>{s.label}</Th>)} 
          <Th color='white' fontSize={12}>Actions</Th>
          </Tr>
        </Thead>

        {isLoading? <Spinner size="lg" borderWidth={2} />: 

        Array.isArray(records) &&
        <Tbody  overflowY='scroll' >
          {filter_applied? 
         filtered_records.map((record,index)=>{
           console.log(record)
          return   <Tr>
              {schema.map(s=>{
                let value ;
                if(s.show.get){
                  if(s.populated){
                    value = record[`${s.dbvalue}_populated`][0][s.populated[0]]
                  }else{
                  if(s.type=='date'){ 
                      value= dateformat(record[s.dbvalue],'d-m-yyyy')
                  }else if(s.type==='boolean'){
                   value = <Badge colorScheme={`${record[s.dbvalue]===true?"green":"red"}`}>{record[s.dbvalue]===true?"Yes":"No"}</Badge>
                  }else{ value = record[s.dbvalue]}
                  } 
                  return  <Td>{value}</Td>
                  }
              }
              )}
            
              <Td>
              <Button  colorScheme="teal" marginRight={4} 
              onClick={()=>{
                seteditRecord(record)
                onOpen()
              }}
              >Edit</Button>
              <DeleteRecord queryString={queryString} deleteUrl={`${getUrl}/${record._id}`} />
              </Td>
              </Tr>
        })  
          
          :data.data.map((record,index)=>{
            return   <Tr>
                {schema.map(s=>{
                  let value ;
                  if(s.show.get){
                    if(s.populated){
                      value = record[`${s.dbvalue}_populated`][0][s.populated[0]]
                    }else{
                    if(s.type=='date'){ 
                        value= dateformat(record[s.dbvalue],'d-m-yyyy')
                    }else if(s.type==='boolean'){
                     value = <Badge colorScheme={`${record[s.dbvalue]===true?"green":"red"}`}>{record[s.dbvalue]===true?"Yes":"No"}</Badge>
                    }else{ value = record[s.dbvalue]}
                    } 
                    return  <Td>{value}</Td>
                    }
                }
                )}
              
                <Td>
                <Button  colorScheme="teal" marginRight={4} 
              onClick={()=>{
                seteditRecord(record)
                onOpen()
              }}
              >Edit</Button> 
                 <DeleteRecord queryString={queryString} deleteUrl={`${getUrl}/${record._id}`} />
                </Td>
                </Tr>
          })} 
        

      </Tbody>
      }
        
        <Tfoot background='teal'>
          <Tr>
          {schema.map(s=> s.show.get &&<Th color='white' fontSize={12}>{s.label}</Th>)}
          <Th color='white' fontSize={12}>Actions</Th>
          </Tr>
        </Tfoot>
      </Table>
      <EditRecord
        queryString={queryString}
        schema={schema} 
        formValues={formValues} 
        record={editRecord}
        title={title}
        getUrl={getUrl}
        action={action}
        validationSchema={validationSchema}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        
                />
      </Box>
        </Box>
    )
}



const MySkeleton = ()=>{
    return <Stack>
    <Skeleton height="20px"/>
    <Skeleton height="20px" />
    <Skeleton height="20px" />
  </Stack>
}