import Images from "../Images";
const CustomersData = [
  {
    id: 1,
    name: "Name",
    pic: Images.profile_pic,
    mobile: "690000000",
    address: "Kifisia,UK",
    telephone: "290000000"
  },
  {
    id: 2,
    name: "Name",
    pic: Images.profile_pic,
    mobile: "690000000",
    address: "Kifisia,Athens",
    telephone: "290000000"
  },
  {
    id: 3,
    name: "Name",
    pic: Images.profile_pic,
    mobile: "690000000",
    address: "Kifisia,Athens",
    telephone: "290000000"
  },
  {
    id: 4,
    name: "Name",
    pic: Images.profile_pic,
    mobile: "690000000",
    address: "Kifisia,Athens",
    telephone: "290000000"
  },
  {
    id: 5,
    name: "Name",
    pic: Images.profile_pic,
    mobile: "690000000",
    address: "Kifisia,Athens",
    telephone: "290000000"
  }
];
export default CustomersData;
