import React from "react";
import "../Styles/NavBar.css";
export default function TitleRow({ change1, change2 }) {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-3">
          <span className="gray_text">CARRIER NAME</span>
        </div>
        {/* <div className="col-2">
          <span className="gray_text">CUSTOMER NAME</span>
        </div> */}
        <div className="col-3 d-flex align-items-center">
          <span className="gray_text">MOBILE</span>
        </div>
        <div className="col-3">
          <span className="gray_text"> {change1} </span>
        </div>
        <div className="col-3 d-flex justify-content-center">
          <span className="gray_text"> COST</span>
        </div>

        {/* <div className="col-2">
          <span className="gray_text">{change2}</span>
        </div> */}
      </div>
    </div>
  );
}
