import React, { Component } from 'react'
import MultiSelect from "react-multi-select-component"
import ReactModal from 'react-modal';
import {connect} from 'react-redux'
 class CustomerChatModal extends Component {
   constructor(props) {
      super(props);
      this.state = {
        carriers_selected:[],
      };
    }
   
   render() {
      return (
        <ReactModal
        id='no_split_modal'
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        shouldCloseOnEsc={true}
        className='split_modal'
        overlayClassName='split_modal_overlay'
        shouldCloseOnOverlayClick={true}
      >
        <p className='mt-3 ml-2 gray_text bold '>Customer Chat</p>
        <button  style={{position:'fixed',top:10,right:10,background:'transparent',border:0}}
        onClick={this.props.onClose}>
        <img style={{height:'2em',width:'2em'}}src={require("../../Pics/close.png")}></img>
        </button>
        

      </ReactModal>
      )
   }
}


 
 export default connect(null,null)(CustomerChatModal);